import React, { useContext, useState } from 'react';
import { Popover } from 'react-bootstrap';
// import "./PaymentListUser.css";
import AuthContext from 'context/AuthContext';
import PaymentContext from 'context/PaymentContext';
import PurebrandLoader from 'components/Loader/PurebrandLoader';
import { convertUTCToLocal, utcLocalTimeStringConversion } from 'utils/dateFormatter';
import { calculateVideoDuration } from 'utils/dateFormatter';
import { DATE_FORMATS, DEMO_HISTORY_STATUS } from 'utils/constants';
import { success, guestNoShow, backupSuccess, saleCanceled, saleFulfilled, salePending } from 'assets/images';

const PaymentListUser = (props) => {
  const { user } = useContext(AuthContext);
  const { payToHostUpdate, payToHostlist, payToHostlistPaid } =
    useContext(PaymentContext);

  const [loading, setLoading] = useState(false);
  const commission = props?.filters?.commissionFitler || null; // The commission filter
  const statusFilter = props?.filters?.statusFilter || null; // The status filter
  const startDateFilter = props?.filters?.startDate || null; // The start date filter
  const endDateFilter = props?.filters?.endDate || null; // The end date filter
  const FailedStatus = [
    'card_declined',
    'payment_blocked',
    'expired_card',
    'error_creating_intent',
    'payout_failed',
    'failed',
  ];

  const handleUnpaidPayment = (status, message, type, stats) => {
    setLoading(true);
    const data = {
      status: status,
      message: message,
      type: type,
      payment_status: stats,
    };
    payToHostUpdate(data, props?.hostDetails.id)
      .then(() => {
        payToHostlist('?order=true&paid=false');
        payToHostlistPaid('?order=true&paid=true');
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const getDemoResult = (
    guestAttended,
    scheduledAt,
    startedAt,
    endedAt,
    fallback_hosts_notified,
    salesCommissionData // Add sales commission data as parameter
  ) => {
    // Check for sales commission status
    if (salesCommissionData) {
      const { sales_commission_obtained_date, sales_commission_fulfilled_date, sales_commission_cancelled_date } = salesCommissionData;
  
      if (sales_commission_fulfilled_date) {
        
        return (<div style={{display:'flex', alignContent:'space-around'}}><img src={saleFulfilled} alt="Sale Fulfilled" /> Sale Fulfilled</div>);
      }
  
      if (sales_commission_cancelled_date) {
        return (<div style={{display: 'flex', alignContent:"space-around"}}>
          <img src={saleCanceled} alt="Sale Cancelled" style={{marginRight: 2}} />
        Sale Cancelled
        </div>
      );
      }
  
      if (sales_commission_obtained_date && !sales_commission_fulfilled_date && !sales_commission_cancelled_date) {
        return(
          <div style={{display:'flex', alignContent:'space-around'}}>
          <img src={salePending} alt="Sale Pending" style={{marginRight: 2}}/>
           Sale Pending
          </div>)
      }
    }
  
    // Demo-specific status checks (retain existing logic for demo)
    if (scheduledAt && endedAt) {
      const endedDate = new Date(endedAt);
      const startedDate = startedAt
        ? new Date(startedAt)
        : new Date(scheduledAt);
      const scheduledDate = new Date(scheduledAt);

      let timeDifference;

      if (startedDate < scheduledDate) {
        timeDifference = Math.abs(endedDate - scheduledDate);
      } else {
        timeDifference = Math.abs(endedDate - startedDate);
      }

      if (!guestAttended) {
        if (timeDifference > 600000) {
          return (
            <>
              <img src={guestNoShow} alt='Guest No Show' />{" "}
              {DEMO_HISTORY_STATUS.GUEST_NO_SHOW}
            </>
          );
        } else {
          return (
            <>
              <img src={guestNoShow} alt='Under Time' />{" "}
              {DEMO_HISTORY_STATUS.UNDER_TIME}
            </>
          );
        }
      }

      if (fallback_hosts_notified) {
        return (
          <div style={{display:'flex', alignContent:'space-around'}}>
            <img src={backupSuccess} alt='Back Up Success' style={{marginRight: 4}} />
            {DEMO_HISTORY_STATUS.BACKUP_SUCCESS}
          </div>
        );
      }

      return (
        <>
          <img src={success} alt='Success' />
          {DEMO_HISTORY_STATUS.SUCCESS}
        </>
      );
    }
  
    return '-';
  };
  
  // Modify the existing conditions to include status filtering
const getPaymentStatus = (earning, type) => {
  if ("demo"=== type && earning?.earning_paid !== null) {
    return 'Paid';
  }
  if ("sale"=== type && earning?.sales_commission_paid !== null) {
    return 'Paid';
  }

  if (
    FailedStatus.includes(earning?.stripe_payment_status) ||
    FailedStatus.includes(earning?.dots_payment_status) && type === "demo"
  ) {
    return 'Failed';
  }
  if (type === "sale" && FailedStatus.includes(earning?.stripe_sales_payment_status) || FailedStatus.includes(earning?.dots_sales_payment_status)) {
    return 'Failed';
  }

  return 'Unpaid';
};

// New status filtering logic
const matchesStatusFilter = (statusFilter, earning, type) => {
  if (!statusFilter) return true; // If no status filter, show all
  
  const currentStatus = getPaymentStatus(earning, type);
  return currentStatus.toLowerCase() === statusFilter.toLowerCase();
};

  if (loading) {
    return <PurebrandLoader />;
  }

  const shouldDisplayDemoRow = commission?.toLowerCase() === 'demo';
  const shouldDisplayBothRow = commission?.toLowerCase() !== 'demo' && commission?.toLowerCase() !== 'sales';
  const isSalesCommissionValid = props?.hostDetails?.earning?.sales_commission !== '0.00'
  const shouldDisplaySalesRow = commission?.toLowerCase() === 'sales' && isSalesCommissionValid; // Add condition for sales_commission

  const CalculateDate = (dateObject, type) => {
    if (type === 'sale earned') {
      if (dateObject?.sales_commission_cancellation_date) {
        return 'NA';
      
      } else if (dateObject?.sales_commission_obtained_date) {
        return (utcLocalTimeStringConversion(
          dateObject?.sales_commission_obtained_date
        ) !== 'Invalid date' ? utcLocalTimeStringConversion(
          dateObject?.sales_commission_obtained_date
        ) : '-');
      } else{
        return 'Pending';
      }
    }else if(type === "demo earned"){
      return (utcLocalTimeStringConversion(dateObject?.ended_at) !=="Invalid date"? utcLocalTimeStringConversion(
        dateObject?.ended_at
      ): "NA");
    }
    else if(type === "demo date"){
      return (utcLocalTimeStringConversion(dateObject?.scheduled_date)!=="Invalid date"? utcLocalTimeStringConversion(
                          `${dateObject?.scheduled_date}T${dateObject?.scheduled_time}Z`
                        ): "NA");
    }else if (type === "demo paid"){
      return (dateObject?.earning <= 0 ? "NA": utcLocalTimeStringConversion(dateObject?.earning_paid)!=="Invalid date"? utcLocalTimeStringConversion(dateObject?.earning_paid): "Pending");
    }else if(type === "sale paid"){

      if (dateObject?.sales_commission_cancellation_date) {
        return "NA";
      
      } else if (dateObject?.sales_commission_paid) {
        return utcLocalTimeStringConversion(
          dateObject?.sales_commission_paid
        ) !== "Invalid date"
          ? utcLocalTimeStringConversion(dateObject?.sales_commission_paid)
          : "-";
      } else {
        return "Pending";
      }
    }else{
      console.log("Could not calculate the date:", type, dateObject);
      return "NA";
    }

  };
  const matchesDateFilter = (startDate) => {
    if(!startDateFilter && !endDateFilter ) return true;
    // const formattedStartDate = startDate? startDate?.format(DATE_FORMATS.GENERIC_DATE_FORMAT): null
    return startDate >= startDateFilter && startDate <= endDateFilter;

  }
  return (
    <>
      {/* Demo Commission Row */}
      {(shouldDisplayBothRow || shouldDisplayDemoRow) && matchesStatusFilter(statusFilter, props?.hostDetails?.earning, "demo") && matchesDateFilter(props?.hostDetails?.ended_at) // use ended_at instead of scheduled at.
      && (
        <tr>
          <td><p>{props?.hostDetails?.members?.host_detail?.first_name} {props?.hostDetails?.members?.host_detail?.last_name.charAt(0)}.</p></td>
          <td className='table-product'><p>{props?.hostDetails?.product?.name}</p></td>
          <td><p>{props?.hostDetails?.earning?.earning}</p></td>
          <td><p>{getPaymentStatus(props?.hostDetails?.earning, "demo")}</p></td>
          {/* <td><p>{getPaymentStatus(props?.hostDetails?.earning, 'demo')}</p></td> */}
          <td><p>Demo</p></td>
          <td className='table-result'><p>{getDemoResult(props?.hostDetails?.members?.guest_detail?.attend_meeting, `${props?.schedule?.scheduled_date}T${props?.schedule?.scheduled_time}Z`, props?.hostDetails?.started_at, props?.hostDetails?.ended_at, props?.hostDetails?.fallback_hosts_notified)}</p></td>
          <td><p>{calculateVideoDuration(props?.hostDetails?.started_at, props?.hostDetails?.ended_at, props?.hostDetails?.members?.guest_detail?.attend_meeting, props?.hostDetails?.members?.guest_detail?.left_meeting, `${props?.schedule?.scheduled_date}T${props?.schedule?.scheduled_time}`)}</p></td>
          <td><p>{props?.hostDetails?.members?.guest_detail?.full_name}</p></td>
          <td><p>{CalculateDate(props?.hostDetails, "demo earned")}</p></td>
          <td><p>{CalculateDate(props?.hostDetails?.schedule, "demo date")}</p></td>
          <td><p>{CalculateDate(props?.hostDetails?.earning, "demo paid")}</p></td>
        </tr>
      )}

      {/* Sales Commission Row */}
      {(shouldDisplayBothRow  || shouldDisplaySalesRow) && isSalesCommissionValid && matchesStatusFilter(statusFilter, props?.hostDetails?.earning, "sale") && matchesDateFilter(props?.hostDetails?.earning?.sales_commission_obtained_date) && (
  <tr>
    <td><p>{props?.hostDetails?.members?.host_detail?.first_name} {props?.hostDetails?.members?.host_detail?.last_name.charAt(0)}.</p></td>
    <td className='table-product'><p>{props?.hostDetails?.product?.name}</p></td>
    <td><p>{props?.hostDetails?.earning?.sales_commission}</p></td>
    <td><p>{getPaymentStatus(props?.hostDetails?.earning, "sale")}</p></td>
    {/* <td><p>{getPaymentStatus(props?.hostDetails?.earning, 'sales')}</p></td> */}
    <td><p>Sales</p></td>
    <td className='table-result'><p>{getDemoResult(
      props?.hostDetails?.members?.guest_detail?.attend_meeting,
      `${props?.schedule?.scheduled_date}T${props?.schedule?.scheduled_time}Z`,
      props?.hostDetails?.started_at,
      props?.hostDetails?.ended_at,
      props?.hostDetails?.fallback_hosts_notified,
      {
        sales_commission_obtained_date: props?.hostDetails?.earning?.sales_commission_obtained_date,
        sales_commission_fulfilled_date: props?.hostDetails?.earning?.sales_commission_fulfilled_date,
        sales_commission_cancelled_date: props?.hostDetails?.earning?.sales_commission_cancellation_date
      }
    )}</p></td>
    <td><p>{calculateVideoDuration(props?.hostDetails?.started_at, props?.hostDetails?.ended_at, props?.hostDetails?.members?.guest_detail?.attend_meeting, props?.hostDetails?.members?.guest_detail?.left_meeting, `${props?.schedule?.scheduled_date}T${props?.schedule?.scheduled_time}`)}</p></td>
    <td><p>{props?.hostDetails?.members?.guest_detail?.full_name}</p></td>
    <td><p>{CalculateDate(props?.hostDetails?.earning, "sale earned")}</p></td>
    <td><p>{CalculateDate(props?.hostDetails?.schedule, "demo date")}</p></td>
    <td><p>{CalculateDate(props?.hostDetails?.earning, "sale paid")}</p></td>
  </tr>
)}
    </>
  );
};

export default PaymentListUser;
