import React, { useState } from 'react';
import { Modal, Tabs, Button } from 'antd';
import Dates from 'components/Filters/DatesForAccountingTab/Dates';
import { coloredFilters } from 'assets/images';
import './PaymentAndPayoutModal.css';
import Hosts from 'components/Filters/HostsForAccountingTab/Hosts';
import Products from 'components/Filters/ProductsForAccountingTab/Products';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TEST_IDS } from 'Pages/dashboard/store/Payments/testIds';
import Status from 'components/Filters/Status/Status';
import Commission from 'components/Filters/Commission/Commission';

const PaymentsFilterModal = ({
  showStatus, // Determines whether to show modal or not
  clickStatus, // Function to close modal and possibly reset filters
  activeFilter, // Current active tab (filter)
  setActiveFilter, // Function to change active tab (filter)
  startDateFilter,
  endDateFilter,
  setStartDateFilter,
  setEndDateFilter,
  dateFilter,
  setDateFilter,
  setDisplayFilter,
  applyFilters,
  clearFilters,
  clearFiltersLoading,
  originalDateFilter,
  originalStartDateFilter,
  originalEndDateFilter,
  originalHostFilter,
  originalProductFilter,
  originalStatusFilter,
  originalCommissionFilter,
  setHostFilter,
  hostFilter,
  setProductFilter,
  setStatusFilter,
  statusFilter,
  setCommissionFilter,
  commissionFilter,
  productFilter,
  loading,
  displayFilter,
  setOriginalHostProductDateFilters,
  originalHostProductDateFilters,
  hostProductDateFilter,
  setHostProductDateFilter,
  
}) => {

  const items = [
    {
      key: '1',
      label: 'Date',
      children: (
        <Dates
          startDateFilter={startDateFilter}
          endDateFilter={endDateFilter}
          setStartDateFilter={setStartDateFilter}
          setEndDateFilter={setEndDateFilter}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          setOriginalFilters={setHostProductDateFilter}
          originalDateFilter={originalDateFilter}
          originalStartDateFilter={originalStartDateFilter}
          originalEndDateFilter={originalEndDateFilter}
        />
      ),
    },
    {
      key: '2',
      label: 'Host',
      children: <Hosts setHostFilter={setHostFilter} setOriginalFilters={setHostProductDateFilter} hostFilter={hostFilter} displayFilter={originalHostProductDateFilters}/>,
    },
    {
      key: '3',
      label: 'Product',
      children: (
        <Products
          setProductFilter={setProductFilter}
          setOriginalFilters={setHostProductDateFilter}
          productFilter={productFilter}
          displayFilter={originalHostProductDateFilters}

        />
      ),
    },
    {
      key: '4',
      label: 'Status',
      children: (
        <Status
          setStatusFilter={setStatusFilter}
          statusFilter={statusFilter}
          // setDisplayFilter={setDisplayFilter}
          originalStatusFilter={originalStatusFilter}
        />
      ),
    },
    {
      key: '5',
      label: 'Commission',
      children: (
        <Commission
          setCommissionFilter={setCommissionFilter}
          commissionFilter={commissionFilter}
          // setDisplayFilter={setDisplayFilter}
          originalCommissionFilter={originalCommissionFilter}
        />
      ),
    },
  ];

  const checkClearFilterDisable = () => {
    if (
      !originalDateFilter &&
      !originalHostFilter?.length &&
      !originalProductFilter?.length &&
      !originalStatusFilter?.length &&
      !originalCommissionFilter?.length
    ) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      open={showStatus} // Use showStatus to control modal visibility
      title={
        <div className="filters-title">
          <img src={coloredFilters} alt="" /> Filters
        </div>
      }
      className="filter-modal"
      onCancel={() => {
        clickStatus(true); // Changed from false to true to update the date filter to original if apply is not clicked
      }}
      footer={[
        <div className="filter-btns" key="footer">
          <div className="left-btns">
            <Button
              className="clear-btn"
              loading={clearFiltersLoading}
              onClick={clearFilters}
              disabled={
                clearFiltersLoading || loading || checkClearFilterDisable()
              }
              data-testid={TEST_IDS.CLEAR_BTN}
            >
              Clear All Filters
            </Button>
          </div>
          <div className="right-btns">
            <Button
              key="back"
              onClick={() => {
                clickStatus(true); // changed from false to close modal and revert the changes made in date filter
              }}
              disabled={clearFiltersLoading || loading}
              data-testid={TEST_IDS.CANCEL_BTN}
            >
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              onClick={applyFilters}
              loading={loading}
              disabled={clearFiltersLoading || loading}
              data-testid={TEST_IDS.APPLY_BTN}
            >
              Apply
            </Button>
          </div>
        </div>,
      ]}
    >
      {/* Tabs now start with the activeFilter passed from the parent */}
      <Tabs activeKey={activeFilter} onChange={setActiveFilter} items={items} />
    </Modal>
  );
};

PaymentsFilterModal.propTypes = {
  showStatus: PropTypes.bool,
  clickStatus: PropTypes.func,
  activeFilter: PropTypes.string,
  setActiveFilter: PropTypes.func,
  setDisplayFilter: PropTypes.func,
  startDateFilter: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  endDateFilter: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  setStartDateFilter: PropTypes.func,
  setEndDateFilter: PropTypes.func,
  dateFilter: PropTypes.string,
  setDateFilter: PropTypes.func,
  applyFilters: PropTypes.func,
  clearFilters: PropTypes.func,
  clearFiltersLoading: PropTypes.bool,
  originalDateFilter: PropTypes.string,
  originalStartDateFilter: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  originalEndDateFilter: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  originalHostFilter: PropTypes.arrayOf(PropTypes.string),
  originalProductFilter: PropTypes.arrayOf(PropTypes.string),
  setHostFilter: PropTypes.func,
  hostFilter: PropTypes.arrayOf(PropTypes.string),
  setProductFilter: PropTypes.func,
  productFilter: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
};

export default PaymentsFilterModal;
