import axios from 'axios';
import axiosInstance from './axios';
import * as LocalStorageService from "../utils/localStorageService";

const BaseUrl = 'api/v1/store-list/';

export const getStores = async () => axiosInstance.get(`${BaseUrl}?order=true`);

export const getStorePerspectiveUsers = async (data, param) =>
  axiosInstance.post(`/api/v1/prospective-host/${param ? param : ''}`, data);

export const sendStorePerspectiveUsersMail = async (data, param) =>
  axiosInstance.post(
    `/api/v1/prospective-host-email/${param ? param : ''}`,
    data
  );

export const getCommissionAdmin = async () =>
  axiosInstance.get(`/api/v1/commission-percentage/`);

export const postCommissionAdmin = async (data) =>
  axiosInstance.post(`/api/v1/commission-percentage/`, data);

export const getSoftReviewStore = async (param) =>
  axiosInstance.get(`/api/v1/soft-review/${param}`);

export const postSoftReviewStore = async (body) => {
  const response = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/soft-review/`,
    data: body,
  });
  return response;
};

export const getPublicSoftReview = async (param) => {
  const response = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/soft-review-user/${param}`,
  });
  return response;
};

export const editSoftReviewStore = async (body, param) => {
  const response = await axios({
    method: 'patch',
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/soft-review/${param}/`,
    data: body,
  });
  return response;
};

export const getProductListStore = async (param) =>
  axiosInstance.get(`/api/v1/products/${param}`);

export const updateProductTag = async (id, data) => {
  let response = axiosInstance.patch(`/api/v1/products/${id} `, data);
  return response;
};

export const getProductListTagStore = async (param) =>
  axiosInstance.get(`/api/v1/product-tags/${param}`);

export const addProductListTagStore = async (data) =>
  axiosInstance.post(`/api/v1/product-tags/`, data);

export const updateProductListTagStore = async (id, data) =>
  axiosInstance.patch(`/api/v1/product-tags/${id}/`, data);

export const removeProductListTagStore = async (id) =>
  axiosInstance.delete(`/api/v1/product-tags/${id}/`);

export const getRoleByStoreAdmin = async (param) =>
  axiosInstance.get(`/api/v1/user/admin/${param}/`);

export const addRoleByStoreAdmin = (data) =>
  axiosInstance.post(`/api/v1/user/admin/`, data);

export const updateRoleByStoreAdmin = (role, id) =>
  axiosInstance.put(`/api/v1/user/admin/${id}`, role);

export const reinviteRoleByStoreAdmin = async (uid) =>
  axiosInstance.get(`/api/v1/user/re-invite/${uid}`);

export const downloadMedias = async () =>
  axiosInstance.post(`/api/v1/download-media/`);

export const completeAddRoleByStoreAdmin = async (data, passuid, passtoken) =>
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/user/complete-admin-onboarding/${passuid}/${passtoken}/`,
    data
  );

/**
 * This function is the service to get all the store for a host
 *
 * @return {Object}response object
 */

export const getAllStoresForHost = async (params) => {
  return await axiosInstance({
    method: 'get',
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/get-all-stores/`,
  });
};

/**
 * This function is used to make an api call for downloading the accounting data
 *
 * @param {object} data data object which is to be passed in the body of the api call
 * @return {object} response object from the api call
 */

export const downloadAccountingData = async (data) => {
  return await axios({
    method: 'get',
    responseType: 'blob',
    headers: {
      'Content-Type': 'text/csv',
      'Authorization': `Bearer ${LocalStorageService.getAccessToken()}`,
    },
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/download-accounting-data/${data}`,
  });
};
