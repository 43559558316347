import React from 'react';
import './StoreTermsCondition.css';

/**
 * This component renders the section of terms and conditions for the store admin
 *
 * @return {ReactNode} A React element that renders the section of terms and conditions for the store admin
 */
const StoreTermsCondition = () => {
  const headings = [
    {
      name: 'PARTIES AND ELIGIBILITY',
      ref: 'tc-parties',
    },
    {
      name: 'DEFINITIONS',
      ref: 'tc-definitions',
    },
    {
      name: 'SERVICES AND SUPPORT',
      ref: 'tc-services',
    },
    {
      name: 'MARKETING AND TRADEMARKS.',
      ref: 'tc-marketing',
    },
    {
      name: 'RESTRICTIONS AND MERCHANT RESPONSIBILITIES',
      ref: 'tc-restrictions',
    },
    {
      name: 'CONFIDENTIALITY; PROPRIETARY RIGHTS',
      ref: 'tc-confidentiality',
    },
    {
      name: 'PAYMENT OF FEES',
      ref: 'tc-payment',
    },
    {
      name: 'TERM AND TERMINATION',
      ref: 'tc-term',
    },
    {
      name: 'WARRANTY AND DISCLAIMER',
      ref: 'tc-warranty',
    },
    {
      name: 'PUREBRAND INDEMNITY',
      ref: 'tc-purebrand',
    },
    {
      name: 'LIMITATION OF LIABILITY',
      ref: 'tc-limitation',
    },
    {
      name: 'MISCELLANEOUS',
      ref: 'tc-miscellaneous',
    },
    {
      name: 'EXHIBIT A (Data Privacy and Security)',
      ref: 'tc-exhibit',
    },
  ];

  return (
    <div className="tc-main-container">
      <h3 style={{ textAlign: 'center' }}>PUREBRAND INC.</h3>
      <h3 style={{ textAlign: 'center' }}>
        Purebrand™ Merchant Terms of Service
      </h3>

      <div>
        <p>Last Updated: January 8, 2025</p>
        <p>
          {' '}
          These Merchant Terms of Service (the “Agreement”) constitute a legally
          binding contract between you and Purebrand Inc. By registering for,
          accessing, or using any portion of the Services (as defined below), or
          by selecting “I agree” or “I accept” or making a similar indication of
          assent when presented with this Agreement electronically, you are
          accepting and agreeing to be bound by this Agreement. Please read this
          Agreement carefully before proceeding. If you are not eligible for the
          Services, or if you do not agree to this Agreement, then you do not
          have permission to use the Services.
        </p>
      </div>
      <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
        <h4>Table of Contents</h4>
        <ul>
          {headings.map((heading) => (
            <li
              key={heading.name}
              style={{
                marginLeft: '20px',
              }}
            >
              <a href={`#${heading.ref}`}>{heading.name}</a>
            </li>
          ))}
        </ul>

        <>
          <h4 className="tc-headers" id="tc-parties">
            Parties and Eligibility
          </h4>
          <p className="terms-text-conatiner">
            <p className="terms-text">
              <b>Parties</b>. In this Agreement, “Purebrand” refers to Purebrand
              Inc., and the words “Merchant” and “you” refer to the company,
              individual, or other entity that: (a) operates a Merchant Store
              (as defined below), and (b) is accepting or has accepted this
              Agreement as described above. Any individual registering for the
              Services (or otherwise purporting to accept this Agreement) for or
              in the name of a company or other entity represents and warrants
              that he or she is authorized and intends by those actions to bind
              that company or other entity, as the Merchant, to this Agreement.
            </p>
            <p className="terms-text">
              <b>Eligibility</b>. To be eligible for the Services: (a) you must
              have the power, right, and capacity to form and be bound legally
              by a contract with Purebrand, be at least 18 years old if you are
              an individual, and have a Merchant Store with which the Platform
              integrates (e.g., Shopify); (b) you must not have previously been
              suspended or removed from the Services; and (c) your registration
              for and use of the Services, and your operation of the business
              associated with the Merchant Store, must comply with all
              applicable laws and regulations. You represent and warrant that
              you meet these eligibility criteria. If you become ineligible, you
              must immediately stop using the Services.
            </p>
          </p>
          <h4 className="tc-headers" id="tc-definitions">
            Definitions
          </h4>
          <p className="terms-text-conatiner">
            <p className="terms-text">
              <b>“Account” </b> means Merchant's online account hosted by
              Purebrand through which Merchant may manage aspects of the
              Services (such as setting commissions or other incentives,
              integrating the Merchant Store, selecting a Service Plan, etc.).
            </p>
            <p className="terms-text">
              <b>“Eligible Product” </b> means a product on the Merchant Store
              for which Merchant has used the Integration Materials to enable
              prospective customers to request or view demos of such product.
            </p>
            <p className="terms-text">
              <b>“Guest” </b> means a prospective customer of the Merchant Store
              who requests or views a Platform-enabled demo for an Eligible
              Product.
            </p>
            <p className="terms-text">
              <b>“Host” </b> means an individual who (at any given time) owns an
              Eligible Product and is registered with Purebrand to provide video
              demos on the Platform.
            </p>
            <p className="terms-text">
              <b>“Integration Materials” </b> means application programing
              interfaces, unique tracking links, code snippets, and/or other
              code or materials furnished by Purebrand to Merchant from time to
              time for integrating features of the Platform into the Merchant
              Store.
            </p>
            <p className="terms-text">
              <b>“Merchant Store” </b> means Merchant's e-commerce website or
              other online storefront identified in the Account.
            </p>
            <p className="terms-text">
              <b>“Platform” </b> means the Purebrand software-based platform,
              primarily consisting of hosted services, downloadable
              applications, and integrated third-party services (such as payment
              processing functionality), designed to enable and reward live
              video product demonstrations by existing users to prospective
              customers of those products, as operated by Purebrand and
              generally described at{' '}
              <a href="https://purebrand.io." target="_blank" rel="noreferrer">
                https://purebrand.io
              </a>
            </p>
            <p className="terms-text">
              <b>“Referred Revenue” </b> means Merchant's revenue from sales of
              Eligible Products to Guests that are initiated through links or
              other referral mechanisms designed to track purchases facilitated
              by the Platform (excluding amounts itemized separately from the
              product purchase price that are collected to cover sales tax or
              shipping charges).
            </p>
            <p className="terms-text">
              <b>“Service Plan” </b> means a set, bundle, or tier of Services
              made available by Purebrand for its customers' selection that
              includes, without limitation, features and parameters of the
              Platform, applicable fees, and any other services or limitations
              associated therewith, as further described in Purebrand's
              published documentation (as updated from time to time) and as
              modified by Purebrand from time to time in accordance with this
              Agreement.
            </p>
            <p className="terms-text">
              <b>“Services” </b> means Purebrand's merchant-facing services
              enabled by or related to the Platform and included in the Service
              Plan selected by Merchant and displayed in the Account.
            </p>
          </p>
          <h4 className="tc-headers" id="tc-services">
            Services and Support
          </h4>
          <p className="terms-text-conatiner">
            {' '}
            <p className="terms-text">
              <b>“Services Description” </b> Online merchant features of the
              Platform may include dashboards for defining incentives for Hosts
              and Guests of video product demos, listing Merchant in the
              Platform's directory of participating merchants, access to
              third-party payment processing for paying incentives to Hosts,
              reporting and analytics related to the use of the Platform through
              the Merchant Store, and access to tracking links and other
              Platform-integration materials to enable live video product demos
              on or through the Merchant Store, as features may be made
              available by Purebrand from time to time and based on the
              applicable Service Plan.
            </p>
            <p className="terms-text">
              <b>“Access to Services” </b> Subject to the terms of this
              Agreement, and subject further to any service limitations and/or
              other parameters associated with the Service Plan selected by
              Merchant, Purebrand agrees that Merchant may access and use the
              Services solely in connection with the Merchant Store during the
              term of this Agreement through the web portal, application
              programming interfaces, and/or other applicable interface(s)
              provided or specified by Purebrand.
            </p>
            <p className="terms-text">
              <b>“Security.” </b> Subject to the terms hereof, Purebrand will
              take measures to maintain the security and privacy of data
              processed through the Services as set forth in Exhibit A.
            </p>
            <p className="terms-text">
              <b>“Purpose of Services; Incentives” </b> Merchant may access and
              use the Services solely for its internal business purposes, to
              market and sell Eligible Products to Guests with the participation
              of its Hosts using the Platform. The Account allows Merchant to
              offer its Guests and Hosts certain incentives (such as coupons or
              other discounts for Guests, compensation for Hosts’ time, or
              revenue shares or commissions for Hosts on resulting Guest
              purchases). Merchant (i) warrants and covenants to Purebrand that
              Merchant will fulfill any of its respective incentive commitments
              to its Guests and Hosts, and (ii) agrees to fully defend,
              indemnify, and hold harmless Purebrand from and against any claim
              by a Guest or Host based on any such incentive commitments.
            </p>
            <p className="terms-text">
              <b>“Payment Processing.” </b> Merchant acknowledges and agrees
              that compensation owed by Merchant to Hosts in accordance with
              Merchant's elections within the Account will be fulfilled through
              Purebrand's designated third-party payments processor (the
              “Payments Processor”), which Purebrand may change at any time upon
              reasonable notice to Merchant. Merchant hereby authorizes the
              Payments Processor to debit such Host compensation amounts from
              Merchant's designated bank account and pay those amounts to the
              applicable Hosts via ACH or other electronic funds transfer.
              Merchant also hereby agrees to be bound by any required terms and
              conditions of the Payments Processor, which, as of the date of
              this Agreement, include the terms located at{' '}
              <a href="https://dots.dev/terms" target="_blank" rel="noreferrer">
                https://dots.dev/terms
              </a>{' '}
              and{' '}
              <a
                href="https://dots.dev/privacy"
                target="_blank"
                rel="noreferrer"
              >
                https://dots.dev/privacy
              </a>
              , and agrees to provide (and, where applicable, authorizes
              Purebrand to provide) the necessary information to the Payments
              Processor in order to effectuate such payments to its Hosts
              through the Services. Purebrand agrees to provide all necessary
              notifications from the Payments Processor as well as support for
              Merchant's use of the Payments Processor's services in connection
              with the Services. Merchant authorizes the Payments Processor to
              make any applicable IRS Form 1099 filings on Merchant's behalf for
              payments made through the Payments Processor, but Merchant
              otherwise remains responsible for all of its tax reporting
              obligations, including any filings related to other payments made
              by Merchant to Hosts, if applicable. Merchant acknowledges and
              agrees that Purebrand will have no responsibility for any
              suspension or termination by the Payments Processor of its
              services for Purebrand or Merchant. If the Payments Processor
              suspends or terminates such services for reasons other than
              Merchant's fault, Purebrand will cooperate in good faith with
              Merchant to transition to an alternate payments process through
              the Platform. In any case, however, Merchant (rather than
              Purebrand or the Payments Processor) has ultimate responsibility
              for honoring any payment commitments it makes through the
              Services, and Merchant agrees that each Host is an express
              third-party beneficiary of Merchant's payment obligations
              pertaining to such Host under this Agreement.
            </p>
            <p className="terms-text">
              <b>“Host and Guest Agreements.” </b> Merchant acknowledges that
              Purebrand may require Hosts and Guests to enter into and comply
              with binding agreements with Purebrand whereby (i) Hosts will
              access and use the Host-facing features of the Platform through a
              downloadable software application provided by Purebrand, and (ii)
              Guests will access and use the Guest-facing features of the
              Platform through a web browser or other Purebrand-supported
              interface at an internet address hosted or approved by Purebrand.
              Such agreements may, among other things, secure for Purebrand
              permission to use (and/or to authorize Merchant or others to use)
              recordings of demos in which the Host or Guest appears for
              marketing purposes, and to use ratings and other statistics
              regarding the Host’s use of the Platform for Purebrand’s lawful
              commercial purposes. Purebrand will have no liability to Merchant
              for suspending or terminating any Host’s or Guest’s access to or
              use of the Platform in accordance with such agreements. Merchant
              agrees that Purebrand does not control, and will have no liability
              on account of, the content of communications between Hosts and
              Guests.
            </p>
            <p className="terms-text">
              <b>“Integration with Merchant Store.” </b> Subject to the terms
              and conditions of this Agreement, Purebrand agrees to provide
              Integration Materials to Merchant, and Merchant agrees to use the
              same in accordance with Purebrand’s applicable documentation or
              other instructions, to enable users in the Merchant Store to
              request and view, and Hosts to provide, video demos of Eligible
              Products through the Platform. Merchant is solely responsible for
              determining whether the Services and Platform, as used with the
              Merchant Store, is permitted by applicable laws and by the
              policies and terms of service that may apply to the Merchant
              Store, and for obtaining any third-party permissions or policy
              exceptions that may be needed to use the Services.
            </p>
            <p className="terms-text">
              <b>“Additional Services.” </b> Purebrand may, in its sole
              discretion, offer other services or features as part of the
              Services (“Additional Services”) from time to time by enabling
              Merchant to select Additional Services in the Account. Merchant’s
              use of Additional Services may be subject to additional terms,
              conditions, fees, guidelines, and limitations that Purebrand posts
              in the Account or otherwise provides to you in relation to those
              Additional Services (in addition to and without diminishing
              Purebrand’s rights or your obligations under this Agreement)
            </p>
          </p>
          <h4 className="tc-headers" id="tc-marketing">
            Marketing and Trademarks
          </h4>
          <p className="terms-text-conatiner">
            <p className="terms-text">
              <b>Use of Recordings. </b> Merchant agrees that Purebrand may use
              recordings of product demos made through the Platform in relation
              to the Merchant Store or Eligible Products for Purebrand’s
              marketing purposes (subject to obtaining any necessary permissions
              of Hosts or Guests appearing in the recordings). Such recordings
              will be the property of Purebrand. In the event that Purebrand
              makes available to Merchant certain recordings of product demos
              for marketing purposes, Purebrand hereby grants to Merchant a
              limited, non-transferable, revocable license to reproduce and
              publicly perform such recordings in its product marketing
              associated with the Merchant Store and/or the product(s) in the
              demo, provided that: (i) Merchant shall promptly cease any such
              activities upon direction from Purebrand, and (ii) Merchant shall
              indemnify, defend, and hold harmless Purebrand from and against
              any claim against Purebrand in connection with or arising from
              Merchant’s use of such recordings.
            </p>
            <p className="terms-text">
              <b>Purebrand Trademarks. </b> Merchant agrees to allow Purebrand’s
              logo(s), trademark(s), and/or tagline(s) (such as “Powered by
              Purebrand”) to be displayed by or through the Integration
              Materials, without alteration or interference, on any page of the
              Merchant Store that integrates with the Platform’s functionality.
              Purebrand hereby grants to Merchant a limited, non-exclusive,
              non-transferable, revocable, royalty-free license during the term
              of this Agreement to use such logo(s), trademark(s), and/or
              tagline(s), specifically as provided by Purebrand in or through
              the Integration Materials, solely to identify Purebrand as the
              provider of the Platform in connection with Merchant’s authorized
              use of the Services on the Merchant Store.
            </p>
            <p className="terms-text">
              <b>Merchant Trademarks. </b> Merchant hereby grants to Purebrand a
              limited, non-exclusive, non-transferable, revocable, royalty-free
              license during the term of this Agreement to use Merchant’s name
              and logo, and any trademark(s) associated with the Eligible
              Products or Merchant Store, solely to identify Merchant as a
              customer of the Services, and to identify the Eligible Products or
              Merchant Store, within the Platform or in Purebrand’s marketing of
              the Platform.
            </p>
            <p className="terms-text">
              <b>Reservation of Rights. </b> As between Purebrand and Merchant,
              each party retains sole and exclusive ownership of the trademarks
              it provides and licenses under this Agreement, and any goodwill
              arising from the other party’s use of such trademarks will inure
              to the benefit of (and be owned by) the owner of such trademarks.
            </p>
          </p>
          <h4 className="tc-headers" id="tc-restrictions">
            Restrictions and Merchant Responsibilities
          </h4>
          <p className="terms-text-conatiner">
            <p className="terms-text">
              <b>Usage Restrictions. </b> Merchant will not, and will not allow
              any person to, directly or indirectly:
              <p>
                <b>(a)</b> access or use the Services via any interface other
                than the interface(s) provided or specified by Purebrand;
                <br />
                <b>(b)</b> access or use the Services for any fraudulent,
                deceptive, or unlawful purpose, or for purposes of monitoring
                the availability, performance or functionality of the Services,
                or to develop any similar or competing solution, or for any
                other benchmarking or competitive purposes;
                <br />
                <b>(c)</b> tamper with, breach, or attempt to circumvent the
                security or authentication measures of the Services or of
                Purebrand’s other accounts;
                <br />
                <b>(d)</b> reverse engineer, decompile, disassemble or otherwise
                attempt to discover the source code, object code or underlying
                structure, ideas, know-how or algorithms relevant to the
                Services or any software, documentation or data related to the
                Services (“Software”);
                <br />
                <b>(e)</b> modify, translate, or create derivative works based
                on the Services or any Software;
                <br />
                <b>(f)</b> lease, distribute, sublicense, sell or otherwise
                commercially exploit the Services (except as expressly
                authorized under this Agreement) or use or permit use of the
                Services or any Software for timesharing or service bureau
                purposes or otherwise for the benefit of a third party;
                <br />
                <b>(g)</b> upload or otherwise introduce to the Services or
                Purebrand’s systems any malware, viruses, Trojan horses,
                spyware, worms, or other malicious or harmful code; or
                <br />
                <b>(h)</b> remove or obscure any proprietary notices or labels
                contained in or displayed by the Services.
              </p>
            </p>
            <p className="terms-text">
              <b>Policy Compliance. </b> Merchant represents, covenants, and
              warrants that Merchant will use the Services only in compliance
              with Purebrand’s standard published policies then in effect (the
              “Policy”) and all applicable laws and regulations. Merchant
              further represents, covenants, and warrants to Purebrand that
              Merchant will have obtained consent from Guests and Hosts
              regarding the contemplated use of personally identifiable
              information of such Guests and Hosts prior to their use of the
              Services that is necessary under laws and regulations applicable
              to such personally identifiable information. Merchant hereby
              agrees to indemnify, defend, and hold harmless Purebrand against
              any damages, losses, liabilities, settlements and expenses
              (including without limitation costs and attorneys’ fees) in
              connection with any claim or action that arises from an alleged
              violation of the foregoing or otherwise from Merchant’s use of
              Services. Although Purebrand has no obligation to monitor
              Merchant’s use of the Services, Purebrand may do so and may
              prohibit any use of the Services it believes may be (or alleged to
              be) in violation of the foregoing.
            </p>
            <p className="terms-text">
              <b>Accounts and Equipment. </b> To access the Services, Merchant
              and any of Merchant’s end users may be required to register on the
              Platform and provide all information reasonably requested as part
              of the registration process. Merchant shall be responsible for
              obtaining and maintaining any equipment and ancillary services
              needed to connect to, access or otherwise use the Services,
              including, without limitation, hardware, software, operating
              systems, networking, web servers and the like (collectively,
              “Equipment”). Merchant shall also be responsible for maintaining
              the security of the Equipment, the Account, passwords (including
              but not limited to administrative and user passwords) and files,
              and for all uses of the Account or the Equipment with or without
              Merchant’s knowledge or consent.
            </p>
            <p className="terms-text">
              <b>Export; Government Use. </b> Further, Merchant may not remove
              or export from the United States or allow the export or re-export
              of the Services, Software or anything related thereto, or any
              direct product thereof in violation of any restrictions, laws or
              regulations of the United States Department of Commerce, the
              United States Department of Treasury Office of Foreign Assets
              Control, or any other United States or foreign agency or
              authority. If Merchant is a government agency or department,
              Merchant acknowledges that the Services, Software, and associated
              documentation are “commercial items,” “commercial computer
              software,” and “commercial computer software documentation” (as
              applicable), as those terms are defined and/or used in applicable
              government procurement regulations and policies, and to the
              fullest extent permitted under applicable law, Merchant’s rights
              with respect to the same are governed solely by the terms of this
              Agreement and are limited to those rights expressly granted by the
              terms of this Agreement.
            </p>
          </p>
          <h4 className="tc-headers" id="tc-confidentiality">
            Confidentiality; Proprietary Rights
          </h4>
          <p className="terms-text-conatiner">
            <p className="terms-text">
              <b>Proprietary Information. </b> Each party (the “Receiving
              Party”) understands that the other party (the “Disclosing Party”)
              has disclosed or may disclose in connection with the Services
              non-public business, technical or financial information relating
              to the Disclosing Party’s business (hereinafter referred to as
              “Proprietary Information” of the Disclosing Party). Proprietary
              Information of Purebrand includes the Integration Materials and
              non-public information regarding the features, functionality,
              performance, and pricing of the Services, and non-public data
              provided by Purebrand to Merchant regarding other users of the
              Platform. Proprietary Information of Merchant includes non-public
              data provided by Merchant to Purebrand regarding its customers or
              Merchant Store transactions to enable the provision of the
              Services (“Merchant Data”). The Receiving Party agrees: (i) to
              take reasonable precautions to protect such Proprietary
              Information, and (ii) not to use (except in performance of the
              Services or as otherwise permitted herein) or divulge to any third
              person (other than the Payments Processor to facilitate payouts in
              connection with the Services) any such Proprietary Information
              without the Disclosing Party’s express permission. The Disclosing
              Party agrees that the foregoing shall not apply with respect to
              any information that the Receiving Party can document (a) is or
              becomes generally available to the public, or (b) was in its
              possession or known by it prior to receipt from the Disclosing
              Party, or (c) was rightfully disclosed to it without restriction
              by a third party, or (d) was independently developed without use
              of any Proprietary Information of the Disclosing Party or (e) is
              required to be disclosed by law. Upon any termination, Purebrand
              will make all Merchant Data stored on Purebrand’s systems
              available to Merchant for electronic retrieval for a period of 30
              days, but thereafter Purebrand may, but is not obligated to,
              delete stored Merchant Data.
            </p>
            <p className="terms-text">
              <b>Ownership. </b> Merchant retains all right, title and interest
              that it may have in and to the Merchant Data. Subject to the
              foregoing, Purebrand shall own all right, title and interest in
              and to (a) the Platform, Services, Integration Materials, and
              Software, and all improvements, enhancements, and modifications
              thereto, including any software, applications, inventions, and
              other technology developed in connection with the foregoing, and
              (b) all intellectual property rights related to any of the
              foregoing. No rights or licenses in the foregoing are granted
              except as expressly set forth herein.
            </p>
            <p className="terms-text">
              <b>Analytics. </b> Notwithstanding anything to the contrary,
              Merchant agrees that Purebrand may collect and analyze data and
              other information relating to the provision, use, and performance
              of various aspects of the Services, Platform, and related systems
              and technologies (including, without limitation, information
              concerning Merchant Data and data derived therefrom), and
              Purebrand will be free (during and after the term hereof) to (i)
              use such information and data to improve and enhance the Services
              and Platform and for other development, diagnostic and corrective
              purposes in connection with the Services, Platform, and/or other
              Purebrand offerings, and (ii) disclose such data solely in
              aggregate or other de-identified form in connection with its
              business.
            </p>
            <p className="terms-text">
              <b>Feedback. </b> Purebrand has not agreed to and does not agree
              to treat as confidential any Feedback (as defined below) that
              Merchant, Hosts, or Guests divulge to Purebrand, and nothing in
              this Agreement or in the parties’ dealings arising out of or
              related to this Agreement will restrict Purebrand’s right to use,
              profit from, disclose, publish, keep secret, or otherwise exploit
              Feedback, without compensating or crediting Merchant or any other
              person. Feedback will not be considered Merchant’s trade secrets.
              “Feedback” refers to any feedback offered to Purebrand regarding
              the Services or Platform, and any suggestions and ideas for
              improving, modifying, or developing any of Purebrand’s products or
              services.
            </p>
          </p>
          <h4 className="tc-headers" id="tc-payment">
            Payment of Fees
          </h4>
          <p className="terms-text-conatiner">
            <p className="terms-text">
              <b>Platform Subscription and Success Fees </b> Merchant will pay
              Purebrand (a) monthly in advance for the Platform access and use
              of the Services and (b) for commissions on Referred Revenue in
              accordance with Merchant’s Service Plan as displayed in the
              Account (collectively, the “Fees”). Purebrand reserves the right
              to change the Fees for any particular Service Plan and to
              institute new charges and Fees, effective upon the earlier of (i)
              Merchant’s selection of a new Service Plan or (ii) the beginning
              of the payment period following 30 days prior notice to Merchant
              (which may be sent by email), provided that under this clause (ii)
              Purebrand allows Merchant at least 30 days to terminate this
              Agreement in order to avoid any new or increased Fees.
            </p>
            <p className="terms-text">
              <b>Host Compensation. </b> In accordance with this Agreement,
              including Sections 3.4 and 3.5, Merchant will compensate Hosts in
              accordance with the commissions set by Merchant in the Account,
              including demo, sales, and no-show commissions. Further, Merchant
              will be responsible for and pay the then-applicable processing
              fees displayed in the Account and charged by the Payments
              Processor for Host compensation transactions hereunder.
            </p>
            <p className="terms-text">
              <b>Fee Disputes. </b> If Merchant believes that Purebrand has
              billed Merchant incorrectly, Merchant must contact Purebrand no
              later than 60 days after the closing date on the first billing
              statement in which the error or problem appeared, in order to
              receive an adjustment or credit. Inquiries should be directed to
              Purebrand’s customer support department.
            </p>
            <p className="terms-text">
              <b>Other Payment Terms. </b> Purebrand may choose to request
              payment of Fees from Merchant through the Payments Processor where
              applicable, in which case Merchant authorizes the amounts due to
              be debited from its bank account and paid to Purebrand via ACH or
              other electronic funds transfer according to the Payments
              Processor’s applicable policies and practices, or Purebrand may
              bill through an invoice, in which case full payment must be
              received by Purebrand 30 days after the issuance date of the
              invoice. Unpaid amounts are subject to a finance charge of 1.5%
              per month on any outstanding balance, or the maximum permitted by
              law, whichever is lower, plus all expenses of collection and may
              result in immediate termination of service. Merchant shall be
              responsible for all taxes associated with the Fees or Services
              other than U.S. taxes based on Purebrand’s net income.
            </p>
            <p className="terms-text">
              <b>Reporting and Audit. </b> Merchant agrees that Purebrand may
              (directly or through an independent third-party auditor) inspect
              and audit Merchant’s books and records upon reasonable notice to
              Merchant (but not more than once in any calendar year as long as
              Merchant is in compliance with its payment and reporting
              obligations hereunder) to verify the accuracy of amounts reported
              and paid by Merchant under this Agreement. Merchant will promptly
              pay any amounts found owing in any such audit. Purebrand will bear
              its own costs associated with any audit under this section unless
              it reveals an underpayment of more than 5% for the period audited,
              in which case Merchant shall promptly reimburse Purebrand’s
              reasonable costs of the audit.
            </p>
          </p>
          <h4 className="tc-headers" id="tc-term">
            Term and Termination
          </h4>
          <p className="terms-text-conatiner">
            <p className="terms-text">
              <b>Term of Agreement. </b> This Agreement is effective when you
              select “I agree” or “I accept” or make a similar indication of
              assent when presented with this Agreement electronically, and will
              continue from month-to-month until terminated (each such month, a
              “Service Period”).
            </p>
            <p className="terms-text">
              <b>Merchant Right to Pause Services </b> Merchant may pause the
              Services at any time through the Account. If paused, the Services
              will be suspended, the applicable Service Period will be extended
              accordingly (until Services are resumed), and any demos or other
              meetings scheduled by Hosts and Guests will be immediately
              cancelled and such Hosts and Guests will receive a notification of
              such cancellation.
            </p>
            <p className="terms-text">
              <b>Merchant Right to Terminate. </b> Merchant may terminate this
              Agreement at any time upon notice to Purebrand, which termination
              will be effective on the final day of the then-current Service
              Period. Any demos scheduled by Hosts and Guests for after the
              effective date of termination will be automatically cancelled and
              such Hosts and Guests will receive a notification of such
              cancellation.
            </p>
            <p className="terms-text">
              <b>Other Termination. </b> Purebrand may suspend the Services at
              any time and will make commercially reasonable efforts to provide
              notice thereof. Purebrand may terminate this Agreement at any time
              upon notice, which termination will be effective on the final day
              of the then-current Service Period. Merchant will pay in full for
              the Services up to and including the last day on which the
              Services are provided.
            </p>
            <p className="terms-text">
              <b>Survival. </b> All sections of this Agreement which by their
              nature should survive termination will survive termination,
              including, without limitation, accrued rights to payment,
              Merchant’s obligations to honor incentive commitments,
              indemnification obligations, confidentiality obligations, warranty
              disclaimers, and limitations of liability.
            </p>
          </p>
          <h4 className="tc-headers" id="tc-warranty">
            Warranty and Disclaimer
          </h4>
          <p className="terms-text-conatiner">
            <p className="terms-text">
              Purebrand shall use reasonable efforts consistent with prevailing
              industry standards to maintain the Services in a manner which
              minimizes errors and interruptions in the Services. Services may
              be temporarily unavailable for scheduled maintenance or for
              unscheduled emergency maintenance, either by Purebrand or by
              third-party providers, or because of other causes beyond
              Purebrand’s reasonable control, but Purebrand shall use reasonable
              efforts to provide advance notice in writing or by e-mail of any
              scheduled service disruption. PUREBRAND DOES NOT WARRANT THAT THE
              SERVICES WILL BE UNINTERRUPTED OR ERROR FREE; NOR DOES IT MAKE ANY
              WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE
              SERVICES. EXCEPT AS EXPRESSLY SET FORTH IN THIS SECTION, THE
              SERVICES ARE PROVIDED “AS IS” AND PUREBRAND DISCLAIMS ALL
              WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
              IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, AND NON-INFRINGEMENT.
            </p>
          </p>
          <h4 className="tc-headers" id="tc-purebrand">
            Purebrand Indemnity
          </h4>
          <p className="terms-text-conatiner">
            <p className="terms-text">
              <b>Obligation. </b> Purebrand shall hold Merchant harmless from
              liability to third parties resulting from infringement by the
              Services of any United States patent or any copyright or
              misappropriation of any trade secret, provided Purebrand is
              promptly notified of any and all threats, claims and proceedings
              related thereto and given reasonable assistance and the
              opportunity to assume sole control over defense and settlement;
              Purebrand will not be responsible for any settlement it does not
              approve in writing. The foregoing obligations do not, however,
              apply with respect to portions or components of the Services (i)
              not supplied by Purebrand, (ii) made in whole or in part in
              accordance with Merchant’s specifications, (iii) that are modified
              after delivery by Purebrand, (iv) combined with other products,
              processes, services, materials, or other elements, where the
              alleged infringement relates to such combination, (v) where
              Merchant continues allegedly infringing activity after being
              notified thereof or after being informed of modifications that
              would have avoided the alleged infringement, or (vi) where use of
              the Services is not strictly in accordance with this Agreement.
            </p>
            <p className="terms-text">
              <b>Mitigation. </b> If the Services become, or are expected by
              Purebrand to become, subject to a claim of infringement, Purebrand
              may, at its option and expense (i) replace or modify the Services
              to be non-infringing without materially diminishing its features
              and functionality, (ii) obtain for Merchant a license to continue
              using the Services, or (iii) terminate this Agreement and
              Merchant’s rights hereunder and provide Merchant a refund of any
              prepaid, unused Fees for the affected period of the Service Term.
            </p>
          </p>
          <h4 className="tc-headers" id="tc-limitation">
            Limitation of Liability
          </h4>
          <p className="terms-text-conatiner">
            <p className="terms-text">
              NOTWITHSTANDING ANYTHING TO THE CONTRARY, PUREBRAND AND ITS
              SUPPLIERS (INCLUDING BUT NOT LIMITED TO THE PAYMENTS PROCESSOR AND
              ALL EQUIPMENT AND TECHNOLOGY SUPPLIERS), OFFICERS, AFFILIATES,
              REPRESENTATIVES, CONTRACTORS, AND EMPLOYEES SHALL NOT BE
              RESPONSIBLE OR LIABLE UNDER THIS AGREEMENT OR WITH RESPECT TO ANY
              SUBJECT MATTER OF THIS AGREEMENT OR TERMS AND CONDITIONS RELATED
              THERETO UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY, OR OTHER
              THEORY: (A) FOR ERROR OR INTERRUPTION OF USE, LOSS OR INACCURACY
              OR CORRUPTION OF DATA, COSTS OF PROCUREMENT OF SUBSTITUTE GOODS,
              SERVICES, OR TECHNOLOGY, OR LOSS OF BUSINESS OR REVENUES; (B) FOR
              ANY INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL
              DAMAGES; (C) FOR ANY MATTER BEYOND PUREBRAND’S REASONABLE CONTROL;
              OR (D) FOR ANY AMOUNTS THAT, TOGETHER WITH AMOUNTS ASSOCIATED WITH
              ALL OTHER CLAIMS, EXCEED THE FEES PAID BY MERCHANT TO PUREBRAND
              FOR THE SERVICES UNDER THIS AGREEMENT IN THE SIX (6) MONTHS PRIOR
              TO THE ACT THAT GAVE RISE TO THE LIABILITY, IN EACH CASE, WHETHER
              OR NOT PUREBRAND HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES.
            </p>
          </p>
          <h4 className="tc-headers" id="tc-miscellaneous">
            Miscellaneous
          </h4>
          <p className="terms-text-conatiner">
            <p className="terms-text">
              <b>Severability. </b> If any provision of this Agreement is found
              to be unenforceable or invalid, that provision will be limited or
              eliminated to the minimum extent necessary so that this Agreement
              will otherwise remain in full force and effect and enforceable.
            </p>
            <p className="terms-text">
              <b>Assignment. </b> This Agreement is not assignable, transferable
              or sublicensable by Merchant except with Purebrand’s prior written
              consent. Purebrand may transfer and assign any of its rights and
              obligations under this Agreement without consent.
            </p>
            <p className="terms-text">
              <b>Entire Agreement. </b> This Agreement is the complete and
              exclusive statement of the mutual understanding of the parties and
              supersedes and cancels all previous written and oral agreements,
              communications and other understandings relating to the subject
              matter of this Agreement. Merchant’s use of certain features or
              aspects of the Services may be subject to additional terms and
              conditions, as specified by Purebrand from time to time on the
              Platform or in the Account. All such additional terms and
              conditions are incorporated by this reference into, and made a
              part of, this Agreement as they relate to the corresponding
              features or aspects of the Services.
            </p>
            <p className="terms-text">
              <b>Modifications and Waivers. </b> All waivers and modifications
              must be in a writing signed by both parties, except as otherwise
              provided herein. Notwithstanding the foregoing, Purebrand may
              modify the terms of this Agreement, including any Service Plan,
              upon at least 30 days’ notice to Merchant, and such modifications
              will be effective and binding upon Merchant at the end of such
              notice period unless, within 30 days after receiving such notice,
              Merchant notifies Purebrand that it is terminating this Agreement
              in accordance with Section 8.
            </p>
            <p className="terms-text">
              <b>No Agency. </b> No agency, partnership, joint venture, or
              employment is created as a result of this Agreement and Merchant
              does not have any authority of any kind to bind Purebrand in any
              respect whatsoever.
            </p>
            <p className="terms-text">
              <b>No Support. </b> Purebrand is under no obligation to provide
              technical or other support for the Platform or Services. In
              instances where Purebrand chooses to offer or provide support, the
              support will be subject to Purebrand’s applicable published
              policies, if any.
            </p>
            <p className="terms-text">
              <b>Notices. </b> All notices under this Agreement will be in
              writing and will be deemed to have been duly given: when received,
              if personally delivered; when receipt is electronically confirmed,
              if transmitted by e-mail; the day after the notice is sent, if
              sent for next day delivery by recognized overnight delivery
              service; and upon receipt, if sent by certified or registered
              mail, return receipt requested. In addition, any notice that
              Purebrand provides to Merchant through the Services will be deemed
              to have been duly given when a user under the Account accesses the
              portion of the Services where the notice or a conspicuous link to
              the same is posted.
            </p>
            <p className="terms-text">
              <b>Governing Law; Disputes. </b> This Agreement shall be governed
              by the laws of the State of Utah without regard to its conflict of
              laws provisions. Merchant and Purebrand submit to the personal and
              exclusive jurisdiction of the state courts and federal courts
              located within Salt Lake County, Utah for resolution of any
              lawsuit or court proceeding permitted under this Agreement.
              Notwithstanding the above, Purebrand may seek and obtain
              injunctive or other equitable relief in any court of competent
              jurisdiction to protect its confidential information, intellectual
              property, or other interests under this Agreement. In any action
              or proceeding to enforce rights under this Agreement, the
              prevailing party will be entitled to recover costs and attorneys’
              fees.
            </p>
            <p className="terms-text">
              <b>No Class Actions. </b> MERCHANT AND PUREBRAND AGREE THAT EACH
              PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN ITS OWN CAPACITY
              AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
              REPRESENTATIVE PROCEEDING. Further, unless both Merchant and
              Purebrand agree otherwise, any claim by one party against the
              other may not be consolidated with any other person’s claims, nor
              be heard or adjudicated in any form of a representative or class
              proceeding.
            </p>
          </p>
        </>
      </div>
      <h3 style={{ textAlign: 'center' }} id="tc-exhibit">
        EXHIBIT A
      </h3>
      <h3 style={{ textAlign: 'center' }}>Data Privacy and Security</h3>
      <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
        <>
          <h4 className="tc-headers data-privacy-header">Generally</h4>
          <p className="terms-text-conatiner">
            <p className="terms-text">
              Purebrand shall use commercially reasonable efforts to prevent the
              unauthorized access, use or disclosure of any Merchant data or
              content that Purebrand knows to be sensitive or protected by
              applicable privacy or data-security laws (“Covered Data”) while in
              Purebrand’s custody or control, including through the
              implementation of industry-standard measures designed to maintain
              the security of such data.
            </p>
          </p>
          <h4 className="tc-headers">Legal Compliance</h4>
          <p className="terms-text-conatiner">
            <p className="terms-text">
              Purebrand will maintain appropriate procedures to ensure
              compliance with legislative, regulatory and contractual
              data-protection requirements, including privacy requirements where
              applicable, including reviewing compliance with these procedures
              using a risk-based approach. The foregoing will not be construed
              as diminishing Merchant’s responsibility for obtaining or
              providing any consents, approvals, notices, or disclosures from or
              to third parties (such as data subject or third-party data
              providers) as may be necessary for Purebrand to process Covered
              Data in accordance with the Agreement.
            </p>
          </p>
          <h4 className="tc-headers">Segregation and Retention of Data</h4>
          <p className="terms-text-conatiner">
            <p className="terms-text">
              Purebrand shall implement and maintain commercially reasonable
              procedures to segregate Covered Data from data belonging to
              Purebrand’s other customers. Purebrand will not retain Covered
              Data longer than reasonably necessary to provide the Services or
              to otherwise comply with Merchant’s instructions, and shall in no
              event be required to retain any Covered Data longer than 30 days.
            </p>
          </p>
          <h4 className="tc-headers">Other Procedures and Safeguards</h4>
          <p className="terms-text-conatiner">
            <p className="terms-text">
              Purebrand shall establish and maintain commercially reasonable
              environmental, safety and facility procedures, physical and
              electronic access controls, and other data security procedures and
              safeguards against the unauthorized access, destruction,
              corruption, loss or alteration of the Services and any Covered
              Data. Purebrand will use commercially reasonable efforts to
              require its third-party hosting provider to maintain and comply
              with programs, procedures, and safeguards consistent with the
              foregoing requirements.
            </p>
          </p>
          <h4 className="tc-headers">Breach Notification and Remediation</h4>
          <p className="terms-text-conatiner">
            <p className="terms-text">
              Purebrand shall use commercially reasonable efforts to promptly
              notify Merchant of any material breach of security with respect to
              any Covered Data. Purebrand will reasonably cooperate with
              Merchant regarding communications to affected individuals, and
              will promptly investigate and diligently pursue remediation of any
              such breach.
            </p>
          </p>
          <h4 className="tc-headers">Continuity and Disaster Recover</h4>
          <p className="terms-text-conatiner">
            <p className="terms-text">
              Purebrand has and will maintain throughout the term of this
              Agreement commercially reasonable procedures designed to promote
              the continuity of Services operations and to facilitate the prompt
              recovery of the Services in the event of a disaster or other
              failure, including an appropriate disaster recovery plan,
              emergency mode operation plan, and regular system and data
              backups. Purebrand will use commercially reasonable efforts to
              require its third-party hosting provider to maintain appropriate
              continuity and disaster recovery measures.
            </p>
          </p>
        </>
      </div>
    </div>
  );
};

export default StoreTermsCondition;
