import "./style.css";
import React from "react";
import { PieChart, Pie, Tooltip, Cell } from "recharts";


const COLORS = ["#658C4A", "#FF0000"];

export default function RechartsTest({data}) {

  return (
    <div className="chart-container">
      <PieChart width={200} height={200} className="chart">
        <Pie
          data={data}
          cx={100} // Center horizontally
          cy={100} // Center vertically
          innerRadius={50}
          outerRadius={60}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
          label
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
      {/* Legend Section */}
      <div className="legend">
        {data.map((entry, index) => (
          <div key={`legend-${index}`} className="legend-item">
            <div className="legend-color" style={{ backgroundColor: COLORS[index % COLORS.length] }} />
            <span>{entry.name}</span>
          </div>
        ))}
        </div>
    </div>
  );
}