import { Checkbox, Input } from 'antd';
import React, { useContext, useState } from 'react';
import './Stores.css';
import DemoContext from 'context/DemoContext';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

/**
 * This component renders the section of stores Filter tab in the filters modal
 *
 * @param {props} props containing the state information of the filter stores
 * @return {ReactNode} A React element that renders the section of stores Filter tab in the filters modal
 */
const Store = ({ setStoreFilter, storeFilter, setOriginalFilters, displayFilter }) => {
  const { allStores } = useContext(DemoContext);
  const Stores = allStores.map((store) =>  store.store_details);
  const [filteredStores, setFilteredStores] = useState(Stores);
  /**
   * This function is a debounce function for filtering out the stores based on search
   *
   * @param {string} searchTerm search value
   * @return {void}
   */

  const debouncedFilter = debounce((searchTerm) => {
    if (searchTerm.length > 1) {
      const filtered = allStores.filter((store) =>
        `${
          store.store_details
            ? store.store_details.store_name
            : store.product.store.name
        }`
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      ).map((store) => store.store_details);
      setFilteredStores(filtered);
    } else {
      setFilteredStores(Stores);
    }
  }, 300);

  /**
   * This function is called on change of the search input
   *
   * @param {event} e input event
   * @return {void}
   */

  const onChangeSearch = (e) => {
    debouncedFilter(e.target.value);
  };

  /**
   * This function is called on change of the checkbox
   *
   * @param {event} e current checked value reference
   * @return {void}
   */

  const onChangeCheckbox = (e, store) => {
    const storeId = e.target.value;
    const storeName = store.store_name;
    
    if (e.target.checked) {
      // Add the selected store
      const newStoreFilter = storeFilter ? [...storeFilter, storeId] : [storeId];
      setStoreFilter(newStoreFilter);
      
      setOriginalFilters((prev) => {
        const currentStores = prev.store || [];
        if (!currentStores.includes(storeName)) {
          return {
            ...prev,
            store: [...currentStores, storeName]
          };
        }
        return prev;
      });
    } else {
      // Remove the unselected store
      const newStoreFilter = storeFilter ? 
        storeFilter.filter(id => id !== storeId) : 
        [];
      setStoreFilter(newStoreFilter);
      
      setOriginalFilters((prev) => ({
        ...prev,
        store: (prev.store || []).filter(name => name !== storeName)
      }));
    }
  };

  return (
    <div className="stores-container">
      <Input
        addonBefore={
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M12.6267 11.5129L16 14.8861L14.8861 16L11.5129 12.6267C10.3 13.5971 8.76177 14.1776 7.08881 14.1776C3.17579 14.1776 0 11.0018 0 7.08881C0 3.17579 3.17579 0 7.08881 0C11.0018 0 14.1776 3.17579 14.1776 7.08881C14.1776 8.76177 13.5971 10.3 12.6267 11.5129ZM11.0465 10.9284C12.0096 9.93584 12.6023 8.58187 12.6023 7.08881C12.6023 4.04259 10.135 1.57529 7.08881 1.57529C4.04259 1.57529 1.57529 4.04259 1.57529 7.08881C1.57529 10.135 4.04259 12.6023 7.08881 12.6023C8.58187 12.6023 9.93584 12.0096 10.9284 11.0465L11.0465 10.9284Z"
                fill="#8DC3B6"
              />
            </svg>
          </>
        }
        placeholder="Search Stores"
        onChange={onChangeSearch}
      />
      <hr />

      <Checkbox.Group
        style={{
          width: '100%',
        }}
        value={storeFilter}
      >
        {filteredStores?.map((store) => {
          return (
            <div className="single-store-div">
              <Checkbox value={store?.store_id} onChange={(e)=>onChangeCheckbox(e, store)}>
                {' '}
                {store?.store_name}
              </Checkbox>
            </div>
          );
        })}
      </Checkbox.Group>
    </div>
  );
};

Store.propTypes = {
  setProductFilter: PropTypes.func,
  productFilter: PropTypes.array || PropTypes.string,
};

export default Store;
