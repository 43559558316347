import { Checkbox, Input } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import './Products.css';
import DemoContext from 'context/DemoContext';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

const Products = ({ setProductFilter, productFilter, setOriginalFilters, displayFilter, originalFilters }) => {
  const { allProducts } = useContext(DemoContext);
  const [filteredProducts, setFilteredProducts] = useState(allProducts);
  const existingProducts = displayFilter.product || [];

  const debouncedFilter = debounce((searchTerm) => {
    const filtered = allProducts.filter((product) =>
      `${product.product ? product?.product?.name : product?.name}`.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, 300);

  const onChangeSearch = (e) => {
    debouncedFilter(e.target.value);
  };

  const onChangeCheckbox = (e, product) => {
    if (!productFilter) {
      // Initialize arrays if they don't exist
      setProductFilter([e.target.value]);
      setOriginalFilters((prev) => ({ ...prev, product: [...prev.product, product.name] }));
    } else {
      if (e.target.checked) {
        // Add the product
        setProductFilter([...productFilter, e.target.value]);
        setOriginalFilters((prev) => ({
          ...prev,
          product: [...prev.product, product.name]
        }));
      } else {
        // Remove the product
        setProductFilter(productFilter.filter((item) => item !== e.target.value));
        setOriginalFilters((prev) => ({
          ...prev,
          product: prev.product.filter((item) => item !== product.name)
        }));
      }
    }
  };

  return (
    <div className="products-container">
      <Input
        addonBefore={
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M12.6267 11.5129L16 14.8861L14.8861 16L11.5129 12.6267C10.3 13.5971 8.76177 14.1776 7.08881 14.1776C3.17579 14.1776 0 11.0018 0 7.08881C0 3.17579 3.17579 0 7.08881 0C11.0018 0 14.1776 3.17579 14.1776 7.08881C14.1776 8.76177 13.5971 10.3 12.6267 11.5129ZM11.0465 10.9284C12.0096 9.93584 12.6023 8.58187 12.6023 7.08881C12.6023 4.04259 10.135 1.57529 7.08881 1.57529C4.04259 1.57529 1.57529 4.04259 1.57529 7.08881C1.57529 10.135 4.04259 12.6023 7.08881 12.6023C8.58187 12.6023 9.93584 12.0096 10.9284 11.0465L11.0465 10.9284Z"
                fill="#8DC3B6"
              />
            </svg>
          </>
        }
        placeholder="Search products"
        onChange={onChangeSearch}
      />
      <hr />

      <Checkbox.Group
        style={{
          width: '100%',
        }}
        value={productFilter}
      >
        {filteredProducts?.map((product) => (
          <div key={product?.identifier} className="single-product-div">
            <Checkbox 
              value={product?.identifier} 
              onChange={(e) => onChangeCheckbox(e, product)}
            >
              {product?.name}
            </Checkbox>
          </div>
        ))}
      </Checkbox.Group>
    </div>
  );
};

Products.propTypes = {
  setProductFilter: PropTypes.func,
  productFilter: PropTypes.array,
  setOriginalFilters: PropTypes.func,
  displayFilter: PropTypes.object
};

export default Products;