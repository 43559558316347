import DemoContext from 'context/DemoContext';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { Alert, Modal, Spinner, Toast, ToastContainer } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  convertTimeZoneFormat,
  convertToUTC,
  extractTimeOptions,
  getTimeArray,
} from 'utils/dateFormatter';
import moment from 'moment-timezone';
import { TEST_IDS } from './testIds';

/**
 * This component renders the reschedule demo modal for the host
 *
 * @param {Object} props details of the demo
 * @return {ReactNode} A react element that renders the reschedule demo modal for the host
 */

const RescheduleModal = (props) => {
  const { rescheduleDemoByHost, userDemos, userAcceptedDemos, hostUserOtherAccepted } =
    useContext(DemoContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showA, setShowA] = useState(false);
  const toggleShowA = () => setShowA(!showA);
  const [submitting, setSubmitting] = useState(false);

  /**
   * This function is used to call reschedule demo api
   *
   * @param {Object} data rescheduled data and time
   * @return {void}
   */
  const rescheduleDemo = (data, props) => {
    const response = rescheduleDemoByHost(data, props?.eventDetail?.id);
    response
      .then(function (response) {
        setShowA(true);
        setSubmitting(false);
        setTimeout(() => {
          setShowA(false);
          userDemos();
          userAcceptedDemos();
          hostUserOtherAccepted("?order=true&offset=0");
          props.clickStatus();
        }, 5000);
      })
      .catch(function (error) {
        setErrorMessage(error);
        setSubmitting(false);
      });
  };

  /**
   * This function is the submit fuction for the revised and updated reshedule flow with exact date and time feature
   *
   * @param {Object} value rescheduled data and time
   * @return {void}
   */

  const revisedHandleSubmit = (values) => {
    setSubmitting(true);
    const data = {
      schedule: {
        scheduled_date: values?.demo_scheduled_time[0],
        scheduled_time: values?.demo_scheduled_time[1],
      },
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    rescheduleDemo(data, props);
  };

  /**
   * This function is the submit fuction for the old reshedule flow with Morning, Afternoon and Evening feature
   *
   * @param {Object} e rescheduled data and time
   * @return {void}
   */

  const handleSubmit = (e) => {
    setSubmitting(true);
    const [utcDate, utcTime] = convertToUTC(
      `${e?.demo_scheduled_date}T${e?.demo_scheduled_time}`
    );
    const data = {
      schedule: {
        scheduled_date: utcDate,
        scheduled_time: utcTime,
      },
      timeZone: Intl.DateTimeFormat()?.resolvedOptions()?.timeZone,
    };

    rescheduleDemo(data, props);
  };
  return (
    <>
      <Modal
        className="reschedule-modal"
        show={props.showStatus}
        onHide={props.clickStatus}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Demo for {props?.eventDetail?.product?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="product-detail-modal-wrapper">
            <p>Guest: {props.eventDetail.members.guest_detail.full_name}</p>
          </div>

          {props?.eventDetail?.date_options?.length > 0 && props?.eventDetail?.date_options[0].time_options !== null &&
          props?.eventDetail?.date_options[0].time_options?.length !== 0 ? (
            <RevisedRescheduleForm
              handleSubmit={revisedHandleSubmit}
              detail={props.eventDetail}
              whatType="Reschedule"
              submitting={submitting}
            />
          ) : (
            <RescheduleForm
              handleSubmit={handleSubmit}
              detail={props.eventDetail}
              whatType="Reschedule"
              submitting={submitting}
            />
          )}
          {errorMessage && (
            <Alert className="mt-1" variant="danger" key={'danger'}>
              {errorMessage?.response?.data.message}
            </Alert>
          )}
        </Modal.Body>
        <ToastContainer className="p-3" position={'middle-center'}>
          <Toast show={showA} onClose={toggleShowA}>
            <Toast.Header closeButton={false}>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">Confirmed new time</strong>
            </Toast.Header>
            <Toast.Body>
              Reschedule request successful! <br />
              Redirecting..
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </Modal>
    </>
  );
};

export default RescheduleModal;

/**
 * This function returns the classes of the form field based on the error of that field
 *
 * @param {Object} errors errors of the fiels
 * @return {String} classname string
 */

const getHourMinClasses = (touched, errors, inputType) => {
  if (inputType === 'hour') {
    if (touched?.hour && errors?.hour) {
      return 'is-invalid';
    } else {
      return '';
    }
  } else {
    if (touched?.minute && errors?.minute) {
      return 'is-invalid';
    } else {
      return '';
    }
  }
};

/**
 * This component renders the old reschedule form of demo modal for the host
 *
 * @param {Object} props details of the demo
 * @return {ReactNode} A react element that renders the old reschedule form of demo modal for the host
 */

export const RescheduleForm = (props) => {
  let { handleSubmit, detail, maximumDate, whatType, submitting } = props;
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [newTiming, setNewTiming] = useState([]);
  const [changed, setChanged] = useState(false);

  const handleChangeDate = () => {
    const timeInclude = [
      5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ];
    setNewTiming(getTimeArray(timeInclude));
  };
  const today = new Date();
  return (
    <Formik
      initialValues={{
        demo_scheduled_date: '',
        demo_scheduled_time: '',
        minute: '00',
      }}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values, touched, errors }) => (
        <Form>
          <div className="row resechedule-wrapper">
            <div className="col-sm-12 col-4 date-input-text-wrapper">
              <div className="csv-datepicker">
                <DatePicker
                  placeholderText={
                    detail ? detail?.schedule?.scheduled_date : today
                  }
                  // dateFormat={"yyyy-MM-dd"}
                  selected={selectedDate}
                  onChange={(e) => {
                    const finalDate =
                      e.getFullYear() +
                      '-' +
                      (e.getMonth() + 1) +
                      '-' +
                      e.getDate();
                    setSelectedDate(e);
                    setFieldValue('demo_scheduled_date', finalDate);
                    setChanged(true);
                    handleChangeDate();
                  }}
                  className="popup-calendar "
                  minDate={today}
                  maxDate={maximumDate ? maximumDate : null}
                  value={values.demo_scheduled_date}
                />
              </div>
            </div>
            <div className="col-sm-12 col-8 select-date-wrapper">
              {changed ? (
                <div className="row">
                  <div className="col col-6">
                    <Field
                      as="select"
                      name="hour"
                      className={`form-select form-select-sm ${getHourMinClasses(
                        'hour'
                      )}`}
                    >
                      <option value="">Hour</option>
                      {newTiming.map((timing, index) => (
                        <option key={index} value={timing.date}>
                          {timing.showing} {timing.meridian}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="col col-6">
                    <Field
                      as="select"
                      name="minute"
                      defaultValue={'00'}
                      onChange={(e) => setFieldValue('minute', e.target.value)}
                      className={`form-select form-select-sm ${getHourMinClasses()}`}
                    >
                      <option value={'00'}>00 minute</option>
                      <option value={'15'}>15 minutes</option>
                      <option value={'30'}>30 minutes</option>
                      <option value={'45'}>45 minutes</option>
                    </Field>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-outline-primary mt-1"
            data-testId={TEST_IDS.RESCHEDULE_BTN}
            disabled={submitting}
            onClick={() => {
              setFieldValue(
                'demo_scheduled_time',
                `${values.hour}:${values.minute}`
              );
            }}
          >
            {submitting ? <Spinner animation="grow" /> : whatType}
          </button>
        </Form>
      )}
    </Formik>
  );
};

/**
 * This component renders the old reschedule form of demo modal for the host
 *
 * @param {Object} props details of the demo
 * @return {ReactNode} A react element that renders the old reschedule form of demo modal for the host
 */

export const RevisedRescheduleForm = (props) => {
  const { handleSubmit, detail, whatType, submitting } = props;

  /**
   * This function is used to get the default date of the demo
   *
   * @return {String} Original date string of the demo
   */

  const getDefaultDate = () => {
    const combinedDateTime = moment(
      `${detail?.schedule?.scheduled_date}T${detail?.schedule?.scheduled_time}Z`
    );
    const localDateTime = combinedDateTime.local();
    return localDateTime.format('YYYY-MM-DD , hh:mm A');
  };

  const [selectedDate, setSelectedDate] = useState(getDefaultDate());

  /**
   * This function is used to check the whether to keep reschedule button disable or not
   *
   * @return {boolean}
   */
  const checkRescheduleDisable = () => {
    if (getDefaultDate() === selectedDate) {
      return true;
    }
    return false;
  };

  return (
    <Formik
      initialValues={{
        demo_scheduled_date: getDefaultDate(),
      }}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values, touched, errors }) => (
        <Form>
          <div className="row resechedule-wrapper revised-schedule">
            <div className="col-sm-12 col-8 select-date-wrapper">
              <div className="col col-6">
                <Field
                  as="select"
                  name="demo_scheduled_date"
                  value={selectedDate}
                  className={`${getHourMinClasses('hour')}`}
                  onChange={(e) => {
                    setSelectedDate(e.target.value);
                  }}
                >
                  {extractTimeOptions(
                    detail.date_options,
                    getDefaultDate(),
                    true
                  ).map((timing, index) => (
                    <option key={index} value={timing}>
                      <span>{timing} </span>
                      <span>
                        ( UTC{moment.tz(moment.tz.guess()).format('Z')}{' '}
                        {convertTimeZoneFormat(moment.tz.guess())} ){' '}
                      </span>
                    </option>
                  ))}
                </Field>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-outline-primary mt-1"
            data-testId={TEST_IDS.RESCHEDULE_BTN}
            disabled={submitting || checkRescheduleDisable()}
            onClick={() => {
              setFieldValue(
                'demo_scheduled_time',
                convertToUTC(`${moment(selectedDate).format('YYYY-MM-DD')}T
                ${moment(selectedDate).format('HH:mm')}`)
              );
            }}
          >
            {submitting ? <Spinner animation="grow" /> : whatType}
          </button>
        </Form>
      )}
    </Formik>
  );
};
