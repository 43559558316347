import { useState } from 'react';
import { useContext } from 'react';
import { createContext } from 'react';
import {
  userDemoRequest,
  userDemoAccepted,
  userAcceptDemo,
  userRejectDemo,
  deleteReviewRequest,
  hostCancelAcceptedDemo,
  rejectDemoRequestStore,
  hostUserRescheduleDemo,
  guestRescheduleDemo,
  getPBAdminHostData,
  getPBAdminCompanyData,
  rejectDemoRequestAdmin,
  companyFullData,
  userDemoAcceptedStore,
  userDemoPendingStore,
  getExistingSoftReview,
  getDemoSatusPublic,
  getHostOtherAccepted,
  demoGuestPresence,
  demoHostPresence,
  authorizeHostForDemo,
  getDemoStatusAuthenticated,
} from 'Services/demoService';
import {
  getShopifyEmailShopId,
  getShopifyProductList,
  getShopifyProductListStore,
} from 'Services/externalApiService';
import {
  getDashboardData,
  getDashboardDataAdmin,
  getDashboardDataStore,
  getDashboardLineDataStore,
} from 'Services/paymentService';
import {
  getFallbackHost,
  addFallbackHost,
  removeFallbackHost,
  requestFallbackHost,
} from 'Services/fallbackHostService';
import AuthContext from './AuthContext';
import { getAllHostsData, getAllProductsData, getAllStoresData } from 'Services/filterService';

const DemoContext = createContext();
export default DemoContext;

export const DemoProvider = ({ children }) => {
  let { userProfile } = useContext(AuthContext);
  const [productList, setProductList] = useState([]);
  const [demoRequests, setDemoRequests] = useState([]);
  const [calendarData, setCalendarData] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [dashboardDataStore, setDashboardDataStore] = useState([]);
  const [dashboardLineDataStore, setDashboardLineDataStore] = useState([]);
  const [adminDashboardLineData, setAdminDashboardLineData] = useState([]);
  const [adminDashboardHostData, setAdminDashboardHostData] = useState([]);
  const [prevStepAdminHostData, setPrevStepAdminHostData] = useState([]);
  const [nextStepAdminHostData, setNextStepAdminHostData] = useState([]);
  const [companyDetailData, setCompanyDetailData] = useState(null);
  const [acceptedOtherData, setAcceptedOtherData] = useState(null);
  const [adminDashboardCompanyData, setAdminDashboardCompanyData] = useState(
    []
  );
  const [prevAdminCompanyData, setPrevAdminCompanyData] = useState([]);
  const [nextAdminCompanyData, setNextAdminCompanyData] = useState([]);
  const [adminDoughnutData, setAdminDoughnutData] = useState(null);
  const [error, setError] = useState(null);
  const [softReviewHistoryData, setSoftReviewHistoryData] = useState(null);
  const [listFallbackHosts, setListFallbackHosts] = useState(null);
  const [fallbackHostCount, setFallbackHostCount] = useState(null);
  const [fallbackHostPrevious, setFallbackHostPrevious] = useState(null);
  const [fallbackHostNext, setFallbackHostNext] = useState(null);
  const [pendingOrder, setPendingOrder] = useState(0);
  const [orderCompleted, setOrderCompleted] = useState(0);
  const [allhosts, setAllHosts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [allStores, setAllStores] = useState([]);

  let getShopifyProduct = async () => {
    let array = [];
    userProfile?.user_shopify.map((user) => array.push(user.store_email));
    let body = {
      customer_emails: array,
    };

    try {
      const response = await getShopifyProductList(body);
      setProductList(response.data.data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  //store meeting details state in localstorage
  const setDataForMeeting = (data) => {
    localStorage.setItem('DETAILS', JSON.stringify(data));
  };

  //displays host product list in store admin's host view
  let getShopifyProductStore = async (body) => {
    try {
      const response = await getShopifyProductListStore(body);
      return response;
    } catch (error) {
      throw error;
    }
  };

  let getPublicShopifyReviewProducts = async (body) => {
    try {
      const response = await getShopifyEmailShopId(body);
      return response;
    } catch (error) {
      throw error;
    }
  };

  let userDemos = async () => {
    try {
      const response = await userDemoRequest();
      setDemoRequests(response.data.data.results);
      return response;
    } catch (error) {
      throw error;
    }
  };

  let storeDemoPendingView = async () => {
    try {
      const response = await userDemoPendingStore();
      setDemoRequests(response.data.data.results);
      return response;
    } catch (error) {
      throw error;
    }
  };
  let userAcceptedDemos = async () => {
    try {
      const response = await userDemoAccepted();
      setCalendarData(response.data.data.results);
      return response;
    } catch (error) {
      throw error;
    }
  };
  let userAcceptedDemosStore = async (params) => {
    try {
      const response = await userDemoAcceptedStore(params);
      setCalendarData(response.data.data.results);
      return response;
    } catch (error) {
      throw error;
    }
  };
  let userAcceptDemoRequest = async (body, param) => {
    try {
      const response = await userAcceptDemo(body, param);
      return response;
    } catch (error) {
      setError(error.response.data);
      throw error;
    }
  };

  let userRejectDemoRequest = async (body, param) => {
    try {
      const response = await userRejectDemo(body, param);
      return response;
    } catch (error) {
      setError(error.response.data);
      throw error;
    }
  };

  let userCancelReviewRequest = async (param) => {
    try {
      const response = await deleteReviewRequest(param);
      return response;
    } catch (error) {
      throw error;
    }
  };

  let hostCancelAccepted = async (param) => {
    let body = {
      status: 'UNDECIDED',
    };
    try {
      const response = await hostCancelAcceptedDemo(body, param);
      return response;
    } catch (error) {
      throw error;
    }
  };

  let respondReviewRequest = async (data, param) => {
    try {
      const response = await rejectDemoRequestStore(data, param);
      return response;
    } catch (error) {
      throw error;
    }
  };

  let respondReviewRequestAdmin = async (data, param) => {
    try {
      const res = await rejectDemoRequestAdmin(data, param);
      return res;
    } catch (error) {
      throw error;
    }
  };
  let rescheduleDemoByHost = async (data, param) => {
    try {
      const response = await hostUserRescheduleDemo(data, param);
      return response;
    } catch (error) {
      throw error;
    }
  };

  let rescheduleDemoByGuest = async (data, param) => {
    try {
      const response = await guestRescheduleDemo(data, param);
      return response;
    } catch (error) {
      throw error;
    }
  };

  let hostDashboardData = async () => {
    try {
      const res = await getDashboardData();
      setDashboardData(res.data.data.results);
      setOrderCompleted(res.data.data.order_completed);
      setPendingOrder(res.data.data.pending_order);
      return res;
    } catch (error) {
      throw error;
    }
  };
  let storeDashboardData = async () => {
    try {
      const res = await getDashboardDataStore();
      setDashboardDataStore(res.data.data.results);
      return res;
    } catch (error) {
      throw error;
    }
  };
  let storeDashboardLineData = async (param) => {
    try {
      const res = await getDashboardLineDataStore(param);
      setDashboardLineDataStore(res.data.data.line_chart);
      return res;
    } catch (error) {}
  };

  let adminHostDataDashboard = async (params) => {
    try {
      const res = await getPBAdminHostData(params);
      setAdminDashboardHostData(res.data.data.results);
      setPrevStepAdminHostData(res.data.data.previous);
      setNextStepAdminHostData(res.data.data.next);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let adminCompanyDataDashboard = async (params) => {
    try {
      const res = await getPBAdminCompanyData(params);
      setAdminDashboardCompanyData(res.data.data.results);
      setNextAdminCompanyData(res.data.data.next);
      setPrevAdminCompanyData(res.data.data.previous);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let getAdminDoughnutData = async (params) => {
    try {
      const res = await getDashboardDataAdmin(params);
      setAdminDoughnutData(res.data.data);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let getAdminLineChartData = async (params) => {
    try {
      const res = await getDashboardDataAdmin(params);
      setAdminDashboardLineData(res.data.data.line_chart);
      return res;
    } catch (error) {
      throw error;
    }
  };
  let getCompanyFullData = async (params) => {
    try {
      const res = await companyFullData(params);
      setCompanyDetailData(res.data);
      return res;
    } catch (error) {
      throw error;
    }
  };
  let softReviewHistory = async (params) => {
    try {
      const res = await getExistingSoftReview(params);
      setSoftReviewHistoryData(res.data.data.results);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let publicDemoStatus = async (params) => {
    try {
      const res = await getDemoSatusPublic(params);
      return res;
    } catch (error) {
      throw error;
    }
  };

  /**
   * This function is used to do an api call to check the demo status.
   *
   * @param {Integer} input input data for the post api
   * @return {Object} response object of the api
   */
  const AuthenticatedDemoStatus = async (params) => {
    try {
      return getDemoStatusAuthenticated(params);
    } catch (error) {
      throw error;
    }
  };
  let hostUserOtherAccepted = async (params) => {
    try {
      const res = await getHostOtherAccepted(params);
      setAcceptedOtherData(res.data.data.results);
      return res;
    } catch (error) {
      throw error;
    }
  };
  let setGuestPresence = async (params, body) => {
    try {
      let res = await demoGuestPresence(params, body);
      return res;
    } catch (error) {
      throw error;
    }
  };
  let setHostPresence = async (params, body) => {
    try {
      let res = await demoHostPresence(params, body);
      return res;
    } catch (error) {
      throw error;
    }
  };

  /**
   * This function is used to do an api call to check whether a host is an authorized host or not.
   *
   * @param {Object} input input data for the post api
   * @return {Object} response object of the api
   */

  const authorizeHost = async (body) => {
    try {
      return await authorizeHostForDemo(body);
    } catch (error) {
      console.log("Auth error", error, body);
      throw error;
    }
  };

  let setFallbackHost = async (body) => {
    try {
      let res = await addFallbackHost(body);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let getAllFallbackHost = async (params) => {
    try {
      let res = await getFallbackHost(params);
      setListFallbackHosts(res?.data?.results);
      setFallbackHostCount(res?.data?.count);
      setFallbackHostPrevious(res?.data?.previous);
      setFallbackHostNext(res?.data?.next);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let deleteFallbackHost = async (id) => {
    try {
      let res = await removeFallbackHost(id);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let requestInCallFallbackHost = async (id) => {
    try {
      let res = await requestFallbackHost(id);
      return res;
    } catch (error) {
      throw error;
    }
  };

  const fetchAllHosts = async (params) => {
    try {
      const res = await getAllHostsData(params);
      setAllHosts(res?.data?.data);
      return res;
    } catch (error) {
      throw error;
    }
  };

  function checkHostProductsOrStore(items) {
    return items.map(item => {
        // Check if the item contains a nested object under 'item'
        if (item.hasOwnProperty('product')) {
            // Return the nested object (item property) if exists
            return item.product;
        }
        // Otherwise, return the item as is
        return item;
    });
}

  const fetchAllProducts = async (params) => {
    try {
      const res = await getAllProductsData(params);
      const products = checkHostProductsOrStore(res.data)
      setAllProducts(products);
      return res;
    } catch (error) {
      throw error;
    }
  };
  
  const fetchAllStores = async (params) => {
    try {
      const res = await getAllStoresData(params);
      setAllStores(res.data.data);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let contextData = {
    userDemos: userDemos,
    userAcceptedDemos: userAcceptedDemos,
    demoRequests: demoRequests,
    calendarData: calendarData,
    userAcceptDemoRequest: userAcceptDemoRequest,
    userRejectDemoRequest: userRejectDemoRequest,
    userCancelReviewRequest: userCancelReviewRequest,
    getShopifyProduct: getShopifyProduct,
    productList: productList,
    hostCancelAccepted: hostCancelAccepted,
    respondReviewRequest: respondReviewRequest,
    error: error,
    setError: setError,
    rescheduleDemoByHost: rescheduleDemoByHost,
    rescheduleDemoByGuest: rescheduleDemoByGuest,
    hostDashboardData: hostDashboardData,
    dashboardData: dashboardData,
    pendingOrder: pendingOrder,
    orderCompleted: orderCompleted,
    storeDashboardData: storeDashboardData,
    dashboardDataStore: dashboardDataStore,
    storeDashboardLineData: storeDashboardLineData,
    dashboardLineDataStore: dashboardLineDataStore,
    adminHostDataDashboard: adminHostDataDashboard,
    adminDashboardHostData: adminDashboardHostData,
    adminDashboardCompanyData: adminDashboardCompanyData,
    adminCompanyDataDashboard: adminCompanyDataDashboard,
    getAdminDoughnutData: getAdminDoughnutData,
    adminDoughnutData: adminDoughnutData,
    getAdminLineChartData: getAdminLineChartData,
    adminDashboardLineData: adminDashboardLineData,
    respondReviewRequestAdmin: respondReviewRequestAdmin,
    getCompanyFullData: getCompanyFullData,
    companyDetailData: companyDetailData,
    userAcceptedDemosStore: userAcceptedDemosStore,
    storeDemoPendingView: storeDemoPendingView,
    getPublicShopifyReviewProducts: getPublicShopifyReviewProducts,
    softReviewHistory: softReviewHistory,
    softReviewHistoryData: softReviewHistoryData,
    publicDemoStatus: publicDemoStatus,
    hostUserOtherAccepted: hostUserOtherAccepted,
    acceptedOtherData: acceptedOtherData,
    setGuestPresence: setGuestPresence,
    setHostPresence: setHostPresence,
    getShopifyProductStore: getShopifyProductStore,
    prevStepAdminHostData: prevStepAdminHostData,
    nextStepAdminHostData: nextStepAdminHostData,
    prevAdminCompanyData: prevAdminCompanyData,
    nextAdminCompanyData: nextAdminCompanyData,
    listFallbackHosts: listFallbackHosts,
    fallbackHostPrevious: fallbackHostPrevious,
    fallbackHostNext: fallbackHostNext,
    fallbackHostCount: fallbackHostCount,
    setFallbackHost: setFallbackHost,
    getAllFallbackHost: getAllFallbackHost,
    deleteFallbackHost: deleteFallbackHost,
    requestInCallFallbackHost: requestInCallFallbackHost,
    setDataForMeeting,
    fetchAllHosts,
    fetchAllProducts,
    fetchAllStores,
    allhosts,
    allProducts,
    allStores,
    authorizeHost,
    AuthenticatedDemoStatus,
    authorizeHost,
  };
  return (
    <DemoContext.Provider value={contextData}>{children}</DemoContext.Provider>
  );
};
