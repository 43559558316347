import React from 'react';
import './PayoutFilterbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

const PayoutFilterbar = ({
  onFilterClick,
  displayFilters,
}) => {
  return (
    <div className="filter-bar">
        <div className="filter-item" onClick={() => onFilterClick('1')}>
          <div className="filter-label">Earn Date <FontAwesomeIcon icon={faCaretDown} /></div>
          
          <div className="filter-value">
            {displayFilters.date || 'Select Date'}
          </div>
        </div>

        <div className="filter-item" onClick={() => onFilterClick('2')}>
          <div className="filter-label">Store <FontAwesomeIcon icon={faCaretDown} /></div>
          
          <div className="filter-value">
            {displayFilters.store?.length > 0 ? displayFilters.store.join(', ') : 'Select Store'}
          </div>
        </div>

        <div className="filter-item" onClick={() => onFilterClick('3')}>
          <div className="filter-label">Product <FontAwesomeIcon icon={faCaretDown} /></div>
          
          <div className="filter-value">
            {displayFilters.product?.length > 0 ? displayFilters.product.join(', ') : 'Select Product'}
          </div>
        </div>

        <div className="filter-item" onClick={() => onFilterClick('4')}>
          <div className="filter-label">Status <FontAwesomeIcon icon={faCaretDown} /></div>
          
          <div className="filter-value">
            {displayFilters.status?.length > 0 ? displayFilters.status : 'Select Status'}
          </div>
        </div>

        <div className="filter-item" onClick={() => onFilterClick('5')}>
          <div className="filter-label">Commission <FontAwesomeIcon icon={faCaretDown} /></div>
          
          <div className="filter-value">
            {displayFilters.commission?.length > 0 ? displayFilters.commission : 'Select Commission'}
          </div>
        </div>
    </div>
  );
};

export default PayoutFilterbar;