import React, { useContext } from 'react';
import './DotsFeesAgreement.css';
import AuthContext from 'context/AuthContext';


/**
 * This component renders the section of the dots fees information
 *
 * @return {ReactNode} A React element that renders the section of dots fees information
 */
const DotsFessAgreement = () => {
  const { userProfile } = useContext(AuthContext);

  return (
    <div className="dots-agreement-container">
      <p>
        {' '}
        <span className="dots-note-header">Note:</span> Fees charged by dots per
        transaction based upon its type are as follows:
        <p>
          <span className="dots-fees-type">Dots Refill fee: </span>
          {userProfile?.store?.app_dots_environment?.DOTS_REFILL_FEE_PERCANTAGE}
          % (min: $
          {userProfile?.store?.app_dots_environment?.DOTS_MINIMUM_REFILL_FEE},
          max: $
          {userProfile?.store?.app_dots_environment?.DOTS_MAXIMUM_REFILL_FEE} )
          <p>
            <span className="dots-fees-type">Dots Domestic payout fee: </span>$
            {userProfile?.store?.app_dots_environment?.DOTS_DOMESTIC_PAYOUT_FEE}
          </p>
        </p>
      </p>
    </div>
  );
};

export default DotsFessAgreement;
