import React, { useState, useRef, useCallback, useContext } from "react";
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import _ from "lodash";
import AuthContext from "context/AuthContext";
import VideoContext from "context/VideoContext";
import DemoContext from "context/DemoContext";
import { useNavigate } from "react-router-dom";

export default function useRoom({setIsLoading}) {
  const { user, loggedIn } = useContext(AuthContext);
  let roomRef = useRef(null);
  let publisherOptionsRef = useRef(null);
  const [camera, setCamera] = useState(null);
  const [screen, setScreen] = useState(null);
  const [localParticipant, setLocalParticipant] = useState(null);
  const [connected, setConnected] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [networkStatus, setNetworkStatus] = useState(null);
  const [publisherIsSpeaking, setPublisherIsSpeaking] = useState(false);
  const [cameraPublishing, setCameraPublishing] = useState(false);
  const { endCall } = useContext(VideoContext);
  const { setGuestPresence } = useContext(DemoContext);
  const navigate = useNavigate();
  const addLocalParticipant = ({ room }) => {
    if (room) {
      setLocalParticipant({
        id: room.participantId,
        name: room.participantName,
      });
    }
  };

  const createCall = useCallback(
    async (
      { appID, serverSecret, roomID, userID, userName, setShowOverlay },
      roomContainer, audioState, videoState
    ) => {
      if (!appID || !serverSecret || !roomID || !userID || !userName) {
        throw new Error("Check your credentials");
      }
      const kitToken = localStorage.getItem("ZEGO_TOKEN");
      roomRef.current = ZegoUIKitPrebuilt.create(kitToken);
      const demoInfo = JSON.parse(localStorage.getItem('DETAILS'))?.sessionDetail;
      const demoIdentifier = demoInfo?.id;
      roomRef.current.joinRoom({
        leaveRoomDialogConfig: {
            confirmCallback: () => {
              setShowOverlay(false);
              setIsLoading(true); // Start loader when API call begins
              if (demoInfo && demoInfo?.status === "UNDECIDED") {
                if (user && loggedIn) {
                    window.location.href = "/user/welcome";
                } else {
                    window.location.href = `/meeting-status-check?demo=${demoIdentifier}`;
                }
            } else {
                const payload = {
                    status: "ENDED",
                };
                const res = endCall(demoIdentifier, payload);
                res
                    .then((res) => {
                      localStorage.removeItem("ZEGO_TOKEN");
                      localStorage.removeItem("demoId");
                      if (user && loggedIn) {
                        navigate("/user/welcome");
                        // window.location.href = "/user/welcome"
                        setIsLoading(false); // Stop loader on success
                        } else {
                          navigate(`/meeting-status-check?demo=${demoIdentifier}`);
                        // window.location.href = `/meeting-status-check?demo=${demoIdentifier}`
                        setIsLoading(false); // Stop loader on success
                      } 
                    })
                    .catch((err) => {
                      // if (demoInfo) { //check whether it is required or not
                        if (user && loggedIn) {
                            window.location.href = '/user/welcome';
                          setIsLoading(false); // Stop loader on success
                        } else {
                          const res = setGuestPresence(demoIdentifier, {
                            status: false,
                          });
                          res
                            .then(() => {
                              
                              // window.location.href = `/meeting-status-check?demo=${demoIdentifier}`
                              navigate(`/meeting-status-check?demo=${demoIdentifier}`);
                              setIsLoading(false); // Stop loader on success

                            })
                            .catch((err) => {
                              console.error('Error while ending the guest call:: setGuestPresence API call failed', err);
                            });
                        }
                      // }
                        // else {
                        //   console.error('Error while ending the call', err);
                        // }
                    })
                    
            } 
            }
          },
          showLeavingView:false,
          showroomtimer: true,
          showScreenSharingButton: true,
          autoHideFooter: false,
          screenSharingConfig: {
            resolution: "auto", // You can define custom logic here or set auto resolution
            width: 1280, // Default or auto width, can adjust dynamically
            height: 720,  // Default or auto height, can adjust dynamically
            frameRate: 15, // Set the frame rate, can be set as default or dynamic
            maxBitRate: 1500, // Set the max bit rate, can be set as default or dynamic
            onError: (errorCode) => {
                // Handle errors during screen sharing
                if (errorCode === 1001) {
                    return 'Screen sharing failed due to permission issues';
                } else if (errorCode === 1002) {
                    return 'Screen sharing stopped unexpectedly';
                }
                return ''; // Return an empty string to customize the UI yourself
            }},
          showPreJoinView: false,
          turnOnMicrophoneWhenJoining: true,
          turnOnCameraWhenJoining: true,
          container: roomContainer,
          branding:{
            logoURL: "https://purebrand-storage.s3.us-east-2.amazonaws.com/logo1.png"
          },
          scenario: {
              mode: ZegoUIKitPrebuilt.VideoConference,
          },
          // onInRoomMessageReceived: (messageInfo) => console.log("Incall message", messageInfo), // Callback for in-room chat message received.
          onUserJoin: (user) => {
            // When a user joins, add them to the participants
            setParticipants((prev) => [...prev, user]);
          },
      });
      // AddHostLeaveButton();
      setConnected(true);
      setCamera(roomRef.current.camera);
      setScreen(roomRef.current.screen);
      addLocalParticipant({ room: roomRef.current });

    },
    [setIsLoading]
  );

  return {
    createCall,
    connected,
    camera,
    screen,
    room: roomRef.current,
    participants,
    networkStatus,
    publisherIsSpeaking,
    cameraPublishing,
    localParticipant,
  };
}
