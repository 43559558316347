import axios from 'axios';
import * as LocalStorageService from '../utils/localStorageService';

/**
 * This function is used to get all the hosts data
 *
 * @param {string} params query params to pass in the api call
 * @return {object} response object
 */
export const getAllHostsData = async (params) => {
  return await axios({
    method: 'get',
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/all-hosts/${params}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + LocalStorageService.getAccessToken(),
      devicetype: 'web',
    },
  });
};

/**
 * This function is used to get all the products data
 *
 * @param {string} params query params to pass in the api call
 * @return {object} response object
 */

export const getAllProductsData = async (params) => {
  return await axios({
    method: 'get',
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/all-products/${params}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + LocalStorageService.getAccessToken(),
      devicetype: 'web',
    },
  });
};
export const getAllStoresData = async (params) => {
  return await axios({
    method: 'get',
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/get-all-stores/${params}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + LocalStorageService.getAccessToken(),
      devicetype: 'web',
    },
  });
}