import { useContext, useEffect, useRef, useState } from "react";
import useRoom from "../../hooks/useRoom";
import AuthContext from "../../context/AuthContext";
import VideoContext from "../../context/VideoContext";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import "../../components/VideoStream/videoStyles.css";
import RecordingModal from "components/Modal/RecordingModal";
import DemoInCallModal from "components/Modal/DemoInCallModal";
import DemoContext from "context/DemoContext";
import SocketContext from "context/SocketContext";
import {useLocation} from "react-router-dom";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import { Modal } from "react-bootstrap";
export default function VideoMeeting() {
  const { state } = useLocation();
  const { AuthenticatedDemoStatus } = useContext(DemoContext);
  const { user, loggedIn } = useContext(AuthContext);
  const { record } = useContext(VideoContext);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [showComissionAlert, setShowComissionAlert] = useState(false);
  const [hasShownAlert, setHasShownAlert] = useState(false); // Flag to ensure modal shows once
  const [videoStatus, setVideoStatus] = useState(state?.videoState);
  const [audioStatus, setAudioStatus] = useState(state?.audioState);
  //screen share
  const [screenShareStatus, setScreenShareStatus] = useState(false);
  const [demoInfo, setDemoInfo] = useState(null);
  const [hostAttendMeeting, setHostAttendMeeting] = useState(null);
  const [showA, setShowA] = useState(true);
  const toggleShowA = () => setShowA(!showA);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showReScheduleModal, setShowRescheduleModal] = useState(false);
  const [remainingTime, setRemainingTime] = useState(60);
  const [showEndCallModal, setShowEndCallModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loader state

  // State to handle overlay visibility
  const [showOverlay, setShowOverlay] = useState(true); // Initially, the overlay is shown
  const [isGuestAttended, setIsGuestAttended] = useState(false); // Track guest attendance

  // Set startTime and scheduleTime based on demoInfo
  const startTime = new Date(demoInfo?.started_at);
  const scheduleTime = new Date(
    `${demoInfo?.schedule?.scheduled_date}T${demoInfo?.schedule?.scheduled_time}Z`
  );
  let minStayTime;

  // Calculate the minStayTime
  if (scheduleTime <= startTime || demoInfo?.soonest_at) {
    minStayTime = new Date(startTime.getTime() + 10 * 60 * 1000);
  } else {
    minStayTime = new Date(scheduleTime.getTime() + 10 * 60 * 1000);
  }

  const nowTime = new Date();
  const [countdown, setCountdown] = useState(calculateCountdown(minStayTime));

  useEffect(() => {
    // Display the alert only once when minStayTime is in the future
    if (minStayTime > nowTime && !hasShownAlert) {
      setShowComissionAlert(true);
      setHasShownAlert(true);
    }

    // Update the countdown and hide the alert when time has passed
    if (minStayTime > nowTime) {
      const intervalId = setInterval(() => {
        setCountdown(calculateCountdown(minStayTime));
      }, 1000);

      return () => clearInterval(intervalId);
    } else {
      setShowComissionAlert(false); // Hide the alert if minStayTime has passed
    }
  }, [minStayTime, hasShownAlert, nowTime]);

  /**
   * Calculates the countdown time (minutes and seconds) from the current time to the target time.
   *
   * @param {Date} targetTime - The target time to calculate the countdown for.
   * @returns {{minutes: number, seconds: number}} - An object containing the remaining minutes and seconds.
   *   - `minutes`: Number of full minutes remaining.
   *   - `seconds`: Number of remaining seconds (0-59).
   */
  function calculateCountdown(targetTime) {
    const now = new Date();
    const difference = targetTime.getTime() - now.getTime();
    const seconds = Math.floor(difference / 1000);

    return {
      minutes: Math.floor(seconds / 60),
      seconds: seconds % 60,
    };
  }

  // Rest of your code remains unchanged
  const [stateDataForVideoMeeting, setStateDataForVideoMeeting] = useState(
    JSON.parse(localStorage.getItem("DETAILS"))
  );
  const demo_id = localStorage.getItem("demoId");

  const { createCall, room, participants, connected } = useRoom({
    setIsLoading,
  });

  const { sendMessage } = useContext(SocketContext);

  const join_room = {
    command: "join_room",
    room_id: demo_id,
  };

  useEffect(() => {
    joinRoomMessage();
  }, []);
  const joinRoomMessage = async () => {
    await sendMessage(join_room);
  };

  const roomContainer = useRef();
  const publisherContainer = useRef();
  const userName = localStorage.getItem("username");
  const token = localStorage.getItem("ZEGO_TOKEN");
  const roomID = localStorage.getItem("demoId");
  useEffectOnce(() => {
    // try fetching the audio video state from previous component
    if (
      token &&
      audioStatus !== null &&
      audioStatus !== undefined &&
      videoStatus !== null &&
      videoStatus !== undefined
    ) {
      createCall(
        {
          appID: process.env.REACT_APP_ZEGO_CLOUD_APP_ID,
          serverSecret: process.env.REACT_APP_ZEGO_CLOUD_SERVER_SECRET,
          roomID: roomID,
          userID: userName,
          userName: userName,
          setShowOverlay: setShowOverlay,
        },
        roomContainer.current,
        true,
        true
      );
    } else if (!audioStatus || !videoStatus) {
      setError({ message: "Some Technical Error, try again" });
      console.log("Could not get the audio and video state");
    } else {
      setError({ message: "credentials to the meeting room not found" });
      console.log("you are not allowed here");
    }
  }, [createCall, user]);

  // Static white overlay with a message
  const renderOverlay = (info) => {
    const commission = info?.commission;
    const {
      no_show_demo_commission: noShowAmount,
      demo_commission: demoAmount,
      no_show_demo_commission_type: noShowType,
      demo_commission_type: demoType,
    } = commission || {};

    const GuestNoShowCommission =
      noShowType === "AMOUNT" ? `$${noShowAmount}` : `${noShowAmount}%`;
    const GuestDemoCommission =
      demoType === "AMOUNT" ? `$${demoAmount}` : `${demoAmount}%`;

    const getOverlayMessage = () => {
      const minutes = parseInt(countdown?.minutes, 10);
      const seconds = parseInt(countdown?.seconds, 10);

      if (Number.isNaN(minutes) || Number.isNaN(seconds)) {
        return {
          text: "Waiting for the guest....",
          style: overlayTextStyle,
        };
      }

      if (minutes >= 1 || seconds >= 1) {
        return {
          text: `${minutes}:${seconds} until you qualify for ${GuestNoShowCommission}`,
          style: overlayTextStyle,
        };
      }

      return {
        text: `You have qualified for ${GuestNoShowCommission}, please stay on the line in case the guest shows up and you can earn ${GuestDemoCommission}.`,
        style: overlayStyleForSuccess,
      };
    };

    const { text, style } = getOverlayMessage();

    return (
      <div style={overlayStyle}>
        <p style={style}>{text}</p>
      </div>
    );
  };
  
  // Styles for the overlay and text
  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "97%",
    backgroundColor: "transparent",
    zIndex: 199, // Ensure it appears over the ZegoCloud container
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "none"
  };

  const overlayTextStyle = {
    fontSize: "24px",
    color: "white",
  };

  const overlayStyleForSuccess = {
    fontSize: "24px",
    color: "green",
  };
  useEffect(() => {
    const res = AuthenticatedDemoStatus(demo_id);
    res.then((data) => {
      setDemoInfo(data?.data?.data);
      setHostAttendMeeting(data?.data?.data?.members?.host_attend_meeting);
      setIsGuestAttended(
        data?.data?.data?.members?.guest_detail?.attend_meeting === null
          ? false
          : true
      );
      setShowOverlay(
        data?.data?.data?.members?.guest_detail?.attend_meeting === null
          ? true
          : false
      );
    });
  }, [participants]);
  useEffect(() => {
    // Hide the overlay for admin if guest attended or if there are at least 2 participants
    if (participants.length >= 2 || isGuestAttended) {
      setShowOverlay(false);
    }
  }, [participants, isGuestAttended]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime(remainingTime - 1);
      } else {
        if (hostAttendMeeting === null) {
          if (!loggedIn) {
            setShowRescheduleModal(true);
          }
        }
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [remainingTime]);

  useEffect(() => {
    if (
      document
        .getElementById("layoutContainer")
        ?.getElementsByClassName(
          "OT_root OT_subscriber OT_fit-mode-cover ot-layout"
        )[0] &&
      document
        .getElementById("layoutContainer")
        .getElementsByClassName(
          "OT_root OT_subscriber OT_fit-mode-cover ot-layout"
        )[0].clientHeight < 350
    ) {
      document
        .getElementById("layoutContainer")
        .getElementsByClassName(
          "OT_root OT_subscriber OT_fit-mode-cover ot-layout"
        )[0].style.top = "50%";
    } else {
    }
  }, [stateDataForVideoMeeting]);

  useEffect(() => {
    if (stateDataForVideoMeeting?.videoState === false) {
      setVideoStatus(false);
      room?.camera?.disableVideo();
    } else {
      setVideoStatus(true);
      room?.camera?.enableVideo();
    }
    if (stateDataForVideoMeeting?.audioState === false) {
      setAudioStatus(false);
      room?.camera?.disableAudio();
    } else {
      setAudioStatus(true);
      room?.camera?.enableAudio();
    }
  }, [stateDataForVideoMeeting]);

  useEffect(() => {
    if (participants.length === 0) {
      setShowA(true);
    } else {
      setShowA(false);
    }
  }, [participants]);

  useEffect(() => {
    if (connected && stateDataForVideoMeeting) {
      (async () => {
        if (
          stateDataForVideoMeeting?.sessionDetail?.meeting_details?.archive_id
        ) {
          handleShow();
        } else {
          try {
            let data = {
              record: true,
            };
            const res = await record(
              demo_id || stateDataForVideoMeeting?.sessionDetail?.id,
              data
            );
            handleShow();
          } catch (error) {
            console.log("Issue Occured While Demo Recording");
          }
        }
      })();
    }
  }, [connected, stateDataForVideoMeeting]);

  if (error) {
    return (
      <div className="card cardc text-center h4 p-5">
        There was an error fetching the data from the server
      </div>
    );
  }

  const getParticipantContainerStyle = (participantsCount) => {
    if (participantsCount === 1) {
      return { width: "30%", height: "120px" };
    } else {
      return { width: "150px", height: "150px" };
    }
  };
  const publisherContainerStyle = getParticipantContainerStyle(
    participants.length
  );
  return (
    <div id="callContainer" className="room-container-custom">
      {!isLoading ? (
        <div className="position-relative" style={{ height: "95%" }}>
          <div
            id="roomContainer"
            style={{ height: "100vh", maxWidth: "100vw" }}
            ref={roomContainer}
          ></div>
          {showOverlay && loggedIn && renderOverlay(demoInfo)}
          <div
            id="publisherContainer"
            style={publisherContainerStyle}
            className="position-absolute"
            ref={publisherContainer}
          >
            <RecordingModal show={show} handleClose={handleClose} />

            {/* TODO: This old fallback logic is no longer in use as per new workflow but can be uncommented if required in future */}
            
            {/* {showReScheduleModal && (
              <DemoInCallModal
                showReScheduleModal={showReScheduleModal}
                setShowRescheduleModal={setShowRescheduleModal}
                demo_id={demo_id}
                demoInfo={demoInfo}
                setRemainingTime={setRemainingTime}
                remainingTime={remainingTime}
              />
            )} */}
          </div>
        </div>
      ) : (
        <PurebrandLoader />
      )}
    </div>
  );
}
