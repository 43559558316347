import { ProfilePicture } from "assets/images";
import React from "react";
import "./payout.css";
import { convertUTCToLocalMyPayoutsFormat } from "utils/dateFormatter";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import CustomModal from "components/Modal/CustomModal";
import AuthContext from "context/AuthContext";
import PaymentContext from "context/PaymentContext";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { utcLocalTimeStringConversion } from "utils/dateFormatter";
import { calculateVideoDuration, convertUTCToLocal } from 'utils/dateFormatter';
import { Icon, guestNoShow, success, backupSuccess, saleCanceled, saleFulfilled, salePending } from 'assets/images';
import { DEMO_HISTORY_STATUS } from 'utils/constants';

const PayoutAccepted = (props) => {
  const { hostDetails, filters, ...rest } = props;
  const commission = props?.filters?.commissionFilter || null; // Commission filter
  const statusFilter = props?.filters?.statusFilter || null; // Status filter
  const startDateFilter = props?.filters?.startDate || null; // The start date filter
  const endDateFilter = props?.filters?.endDate || null; // The end date filter
  const FailedStatus = [
    "card_declined",
    "payment_blocked",
    "expired_card",
    "error_creating_intent",
    "payout_failed",
    "failed"
  ];
  
  const getDemoResult = (
    guestAttended,
    scheduledAt,
    startedAt,
    endedAt,
    fallback_hosts_notified,
    salesCommissionData // Add sales commission data as parameter
  ) => {
    // Check for sales commission status
    if (salesCommissionData) {
      const { sales_commission_obtained_date, sales_commission_fulfilled_date, sales_commission_cancelled_date } = salesCommissionData;
  
      if (sales_commission_fulfilled_date) {
        
        return (<div style={{display:'flex', alignContent:'space-around'}}><img src={saleFulfilled} alt="Sale Fulfilled" /> Sale Fulfilled</div>);
      }
  
      if (sales_commission_cancelled_date) {
        return (<div style={{display: 'flex', alignContent:"space-around"}}>
          <img src={saleCanceled} alt="Sale Cancelled" />
        Sale Cancelled
        </div>
      );
      }
  
      if (sales_commission_obtained_date && !sales_commission_fulfilled_date && !sales_commission_cancelled_date) {
        return(
          <div style={{display:'flex', alignContent:'space-around'}}>
          <img src={salePending} alt="Sale Pending" /> Sale Pending</div>)
      }
    }
  
    // Demo-specific status checks (retain existing logic for demo)
    if (scheduledAt && endedAt) {
          const endedDate = new Date(endedAt);
          const startedDate = startedAt
            ? new Date(startedAt)
            : new Date(scheduledAt);
          const scheduledDate = new Date(scheduledAt);
    
          let timeDifference;
    
          if (startedDate < scheduledDate) {
            timeDifference = Math.abs(endedDate - scheduledDate);
          } else {
            timeDifference = Math.abs(endedDate - startedDate);
          }
    
          if (!guestAttended) {
            if (timeDifference > 600000) {
              return (
                <>
                  <img src={guestNoShow} alt='Guest No Show' />{" "}
                  {DEMO_HISTORY_STATUS.GUEST_NO_SHOW}
                </>
              );
            } else {
              return (
                <>
                  <img src={guestNoShow} alt='Under Time' />{" "}
                  {DEMO_HISTORY_STATUS.UNDER_TIME}
                </>
              );
            }
          }
  
      if (fallback_hosts_notified) {
        return (
          <>
            <img src={backupSuccess} alt="Back Up Success" />
            {DEMO_HISTORY_STATUS.BACKUP_SUCCESS}
          </>
        );
      }
  
      return (
        <>
          <img src={success} alt="Success" />
          {DEMO_HISTORY_STATUS.SUCCESS}
        </>
      );
    }
  
    return '-';
  };
  const getPaymentStatus = (earning, type) => {
    if ("demo"=== type && earning?.earning_paid !== null) {
      return 'Paid';
    }
    if ("sale" === type && earning?.sales_commission_paid !== null) {
      return 'Paid';
    }

    if (
      FailedStatus.includes(earning?.stripe_payment_status) ||
      FailedStatus.includes(earning?.dots_payment_status) && type === "demo"
    ) {
      return 'Failed';
    }
    if (type === "sale" && FailedStatus.includes(earning?.stripe_sales_payment_status) || FailedStatus.includes(earning?.dots_sales_payment_status)) {
      return 'Failed';
    }

    return 'Unpaid';
  };
// New status filtering logic
const matchesStatusFilter = (statusFilter, earning, type) => {
  if (!statusFilter) return true; // If no status filter, show all
  
  const currentStatus = getPaymentStatus(earning, type);
  return currentStatus.toLowerCase() === statusFilter.toLowerCase();
};
  const shouldDisplayDemoRow = commission?.toLowerCase() === 'demo';
  const shouldDisplayBothRow = commission?.toLowerCase() !== 'demo' && commission?.toLowerCase() !== 'sales';
 // Check Sales Commission Value
const isSalesCommissionValid = hostDetails?.earning?.sales_commission !== '0.00';

// Render Sales Row Conditionally
const shouldDisplaySalesRow = commission?.toLowerCase() === 'sales' && isSalesCommissionValid;

const CalculateDate = (dateObject, type) => {
  if (type === 'sale earned') {
    if (dateObject?.sales_commission_cancellation_date) {
      return 'NA';
    
    } else if (dateObject?.sales_commission_obtained_date) {
      return utcLocalTimeStringConversion(
        dateObject?.sales_commission_obtained_date
      ) !== "Invalid date"
        ? utcLocalTimeStringConversion(
            dateObject?.sales_commission_obtained_date
          )
        : "-";
    } else {
      return "Pending";
    }
  }else if(type === "demo earned"){
    return (utcLocalTimeStringConversion(dateObject?.ended_at) !=="Invalid date"? utcLocalTimeStringConversion(dateObject?.ended_at): "NA");
  }
  else if(type === "demo date"){
    return (utcLocalTimeStringConversion(dateObject?.scheduled_date)!=="Invalid date"? utcLocalTimeStringConversion(dateObject?.scheduled_date): "NA");
  }else if (type === "demo paid"){
    return (dateObject?.earning <= 0 ? "NA": utcLocalTimeStringConversion(dateObject?.earning_paid)!=="Invalid date"? utcLocalTimeStringConversion(dateObject?.earning_paid): "Pending");
  }else if(type === "sale paid"){

    // if (type === 'sale earned') {
      if (dateObject?.sales_commission_cancellation_date || FailedStatus.includes(dateObject?.stripe_payment_status) || FailedStatus.includes(dateObject?.dots_payment_status)) {
        return "NA";
      } else if (dateObject?.sales_commission_paid) {
        return utcLocalTimeStringConversion(
          dateObject?.sales_commission_paid
        ) !== "Invalid date"
          ? utcLocalTimeStringConversion(dateObject?.sales_commission_paid)
          : "-";
      } else {
        return "Pending";
        // }
      }
  }else{
    console.log("Could not calculate the date:", type, dateObject);
    return "NA";
  }

};
const matchesDateFilter = (startDate) => {
  if(!startDateFilter && !endDateFilter ) return true;
  // const formattedStartDate = startDate? startDate?.format(DATE_FORMATS.GENERIC_DATE_FORMAT): null
  return startDate >= startDateFilter && startDate <= endDateFilter;

}
  return (
    <>
      {/* {loading && <PurebrandLoader />} */}

      {/* Demo Commission Row */}
      {(shouldDisplayBothRow || shouldDisplayDemoRow) &&matchesStatusFilter(statusFilter, hostDetails?.earning, "demo") && matchesDateFilter(props?.hostDetails?.ended_at) && (
        <tr className="payout-table-items">
          <td><p>{hostDetails?.product?.store?.name}</p></td>
          <td><p>{hostDetails?.product?.name}</p></td>
          <td><p><strong>${hostDetails?.earning?.earning}</strong></p></td>
          <td><p>{getPaymentStatus(hostDetails?.earning, "demo")}</p></td>
          <td><p>Demo</p></td>
          <td><p>{getDemoResult(
              hostDetails?.members[0]?.guest_detail?.attend_meeting,
              `${hostDetails?.schedule?.scheduled_date}T${hostDetails?.schedule?.scheduled_time}Z`,
              hostDetails?.started_at,
              hostDetails?.ended_at,
              hostDetails?.fallback_hosts_notified
            )}
          </p></td>
          <td><p>{calculateVideoDuration(
              hostDetails?.started_at,
              hostDetails?.ended_at,
              hostDetails?.members[0]?.guest_detail?.attend_meeting,
              hostDetails?.members[0]?.guest_detail?.left_meeting,
              `${hostDetails?.schedule?.scheduled_date}T${hostDetails?.schedule?.scheduled_time}`
            )}
          </p></td>
          <td><p>{hostDetails?.members[0]?.guest_detail?.full_name}</p></td>
          <td><p>{CalculateDate(hostDetails, "demo earned")}</p></td>
          <td><p>{CalculateDate(hostDetails?.schedule, "demo date")}</p></td>
          <td><p>{CalculateDate(hostDetails?.earning, "demo paid")}</p></td>
        </tr>
      )}

      {/* Sales Commission Row */}
{(shouldDisplayBothRow || shouldDisplaySalesRow) && isSalesCommissionValid &&matchesStatusFilter(statusFilter, hostDetails?.earning, "sale") && matchesDateFilter(props?.hostDetails?.earning?.sales_commission_obtained_date)&&(
  <tr className="payout-table-items">
    <td><p>{hostDetails?.product?.store?.name}</p></td>
    <td><p>{hostDetails?.product?.name}</p></td>
    <td><p><strong>${hostDetails?.earning?.sales_commission}</strong></p></td>
    <td><p>{getPaymentStatus(hostDetails?.earning, "sale")}</p></td>
    <td><p>Sales</p></td>
    <td className='table-result'><p>{getDemoResult(
      props?.hostDetails?.members?.guest_detail?.attend_meeting,
      `${props?.schedule?.scheduled_date}T${props?.schedule?.scheduled_time}Z`,
      props?.hostDetails?.started_at,
      props?.hostDetails?.ended_at,
      props?.hostDetails?.fallback_hosts_notified,
      {
        sales_commission_obtained_date: props?.hostDetails?.earning?.sales_commission_obtained_date,
        sales_commission_fulfilled_date: props?.hostDetails?.earning?.sales_commission_fulfilled_date,
        sales_commission_cancelled_date: props?.hostDetails?.earning?.sales_commission_cancellation_date
      }
    )}</p></td>
    <td>
      <p>
        {calculateVideoDuration(
          hostDetails?.started_at,
          hostDetails?.ended_at,
          hostDetails?.members[0]?.guest_detail?.attend_meeting,
          hostDetails?.members[0]?.guest_detail?.left_meeting,
          `${hostDetails?.schedule?.scheduled_date}T${hostDetails?.schedule?.scheduled_time}`
        )}
      </p>
    </td>
    <td><p>{hostDetails?.members[0]?.guest_detail?.full_name}</p></td>
    <td><p>{CalculateDate(hostDetails?.earning, "sale earned")}</p></td>
    <td><p>{CalculateDate(hostDetails?.schedule, "demo date")}</p></td>
    <td><p>{CalculateDate(hostDetails?.earning, "sale paid")}</p></td>
  </tr>
)}

    </>
  );
};

export default PayoutAccepted;
