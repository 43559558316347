import React from 'react';
import { Modal, Tabs, Button } from 'antd';
import Dates from 'components/Filters/DatesForAccountingTab/Dates';
import { coloredFilters } from 'assets/images';
import './PayoutFilterModal.css';
import Products from 'components/Filters/ProductsForAccountingTab/Products';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TEST_IDS } from 'Pages/dashboard/store/Payments/testIds';
import Status from 'components/Filters/Status/Status';
import Commission from 'components/Filters/Commission/Commission';
import Stores from 'components/Filters/Stores/Stores';

/**
 * This component renders a modal for applying filters on demo history
 *
 * @param {props} props props containing all the information and states for the filter of the demos
 * @return {ReactNode} A React element that renders a modal for applying filters on demo history
 */

const PayoutFilterModal = ({
  startDateFilter,
  endDateFilter,
  setStartDateFilter,
  setEndDateFilter,
  dateFilter,
  setDateFilter,
  applyFilters,
  clearFilters,
  clearFiltersLoading,
  originalDateFilter,
  originalStartDateFilter,
  originalEndDateFilter,
  originalProductFilter,
  setProductFilter,
  productFilter,
  loading,
  showStatus,
  clickStatus,
  setStoreFilter,
  storeFilter,
  setStatusFilter,
  statusFilter,
  originalStatusFilter,
  setCommissionFilter,
  commissionFilter,
  originalCommissionFilter,
  originalStoreFilter,
  activeFilter, // Current active tab (filter)
  setActiveFilter, // Function to change active tab (filter)
  displayFilter,
  setOriginalStoreProductDateFilter,
  originalStoreProductDateFilter,
  setStoreProductDateFilter,
  storeProductDateFilter,

}) => {
  const items = [
    {
      key: "1",
      label: "Date",
      children: (
        <Dates
          startDateFilter={startDateFilter}
          endDateFilter={endDateFilter}
          setStartDateFilter={setStartDateFilter}
          setEndDateFilter={setEndDateFilter}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          setOriginalFilters={setStoreProductDateFilter}
          originalDateFilter={originalDateFilter}
          originalStartDateFilter={originalStartDateFilter}
          originalEndDateFilter={originalEndDateFilter}
        />
      ),
    },
    {
      key: "2",
      label: "Store",
      children: (
        <Stores
          setStoreFilter={setStoreFilter}
          storeFilter={storeFilter}
          setOriginalFilters={setStoreProductDateFilter}
          displayFilter={originalStoreProductDateFilter}
        />
      ),
    },
    {
      key: "3",
      label: "Product",
      children: (
        <Products
          setProductFilter={setProductFilter}
          setOriginalFilters={setStoreProductDateFilter}
          productFilter={productFilter}
          originalFilters={storeProductDateFilter}
          displayFilter={originalStoreProductDateFilter}
        />
      ),
    },
    {
      key: "4",
      label: "Status",
      children: (
        <Status
          setStatusFilter={setStatusFilter}
          statusFilter={statusFilter}
          originalStatusFilter={originalStatusFilter}
          // setDisplayFilter={setDisplayFilter}
        />
      ),
    },
    {
      key: "5",
      label: "Commission Type",
      children: (
        <Commission
          setCommissionFilter={setCommissionFilter}
          commissionFilter={commissionFilter}
          originalCommissionFilter={originalCommissionFilter}
          // setOriginalStoreHostProductDateFilters={setOriginalStoreHostProductDateFilters}
        />
      ),
    },
  ];

  /**
   * This function is used to check the disability of the clear filter button
   * 
   * @return {Boolean} boolean value for clear filter button disability
   */

  const checkClearFilterDisable = () => {
    if (
      !originalDateFilter &&
      !originalStoreFilter?.length &&
      !originalProductFilter?.length &&
      !originalStatusFilter?.length &&
      !originalCommissionFilter.length
    ) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      open={showStatus}
      title={
        <div className="filters-title">
          <img src={coloredFilters} alt="" /> Filters
        </div>
      }
      className="filter-modal"
      onCancel={() => {
        clickStatus(true);
      }}
      footer={[
        <div className="filter-btns">
          <div className="left-btns">
            <Button
              className="clear-btn"
              loading={clearFiltersLoading}
              onClick={clearFilters}
              disabled={
                clearFiltersLoading || loading || checkClearFilterDisable()
              }
              data-testid={TEST_IDS.CLEAR_BTN}
            >
              Clear All Filters
            </Button>
          </div>

          <div className="right-btns">
            <Button
              key="back"
              onClick={() => {
                clickStatus(true);
              }}
              disabled={clearFiltersLoading || loading}
              data-testid={TEST_IDS.CANCEL_BTN}
            >
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              onClick={applyFilters}
              loading={loading}
              disabled={clearFiltersLoading || loading}
              data-testid={TEST_IDS.APPLY_BTN}
            >
              Apply
            </Button>
          </div>
        </div>,
      ]}
    >
      <Tabs activeKey={activeFilter} onChange={setActiveFilter} items={items} />
    </Modal>
  );
};

PayoutFilterModal.propTypes = {
  showStatus: PropTypes.bool,
  clickStatus: PropTypes.func,
  startDateFilter: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  endDateFilter: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  setStartDateFilter: PropTypes.func,
  setEndDateFilter: PropTypes.func,
  dateFilter: PropTypes.string,
  setDateFilter: PropTypes.func,
  applyFilters: PropTypes.func,
  clearFilters: PropTypes.func,
  clearFiltersLoading: PropTypes.bool,
  originalDateFilter: PropTypes.string,
  originalStartDateFilter: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  originalEndDateFilter: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  setProductFilter: PropTypes.func,
  productFilter: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
};

export default PayoutFilterModal;
