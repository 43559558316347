import { BlankImg, Icon } from "assets/images";
import Dashelement from "components/Dashboard/Dashelement";
import AuthContext from "context/AuthContext";
import DemoContext from "context/DemoContext";
import ProfileContext from "context/ProfileContext";
import React, { useCallback, useContext, useEffect } from "react";
import { useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MeetingReqView from "../Meetings/MeetingReqView";
import * as moment from "moment";
import SuggestedProducts from "./SuggestedProducts";
import DatePicker from "react-datepicker";
import "./hostdashboard.css";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import NotificationContext from "context/NotificationContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import { getAllStoresForHost } from "Services/storeService";
import DotsContext from "context/DotsContext";

const options = {
  responsive: true,

  plugins: {
    tooltip: {
      callbacks: {
        label: (item) => `${item.label}:  $${item.formattedValue}`,
      },
      backgroundColor: "#fff",
      bodyColor: "#359CD7",
    },
    legend: {
      display: false,
    },
    labels: {
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};
const HomePageHost = () => {
  const { user, userProfile, emailVerificationLoading } = useContext(AuthContext);
  let { showrooms, getUserReviews } = useContext(ProfileContext);
  let {
    demoRequests,
    userDemos,
    hostDashboardData,
    dashboardData,
    getShopifyProduct,
    productList,
    // orderCompleted,
    // pendingOrder,
  } = useContext(DemoContext);
  const { notificationPermission } = useContext(NotificationContext);
  const { setStoreList, storeList } = useContext(DotsContext);
  const [loading, setLoading] = useState(true);
  var [preData, setPreData] = useState(0);
  var [postData, setPostData] = useState(0);
  const [currentProductList, setCurrentProductList] = useState("all");
  const [active, setActive] = useState("month");
  const [storesLoading, setStoresLoading] = useState(true);
  const navigate = useNavigate();

  const getDemoRequest = useCallback(() => {
    if (demoRequests.length < 1) {
      return <div className="no-demo-wrap">No demo requests available</div>;
    } else {
      return demoRequests.map((demoRequest, index) => {
        return (
          <MeetingReqView
            key={index}
            demoDetail={demoRequest}
            storesLoading={storesLoading}
            customClass="dashboard-request-view"
          />
        );
      });
    }
  }, [demoRequests, storeList]);
  const getRatingData = useCallback(() => {
    if (dashboardData.length < 1) {
      return <div>No ratings available</div>;
    } else {
      return dashboardData.map((dashboardData, index) => {
        return (
          <li key={index} className="list-group-item">
            <div className="row d-flex">
              <div className="col-sm-12 col-md-2">
                <img
                  className="small-image-list"
                  src={
                    dashboardData.product ? dashboardData.product.image : Icon
                  }
                  alt="product"
                />
              </div>
              <div className="col-sm-12 col-md-8 product--name--wrapper">
                <p className="product-title">
                  {dashboardData.product && dashboardData?.product?.name}
                </p>
                <p className="company">
                  {dashboardData.created_at.split("T")[0]}
                </p>
              </div>
              <div className="col col-md-2 product--price--wrapper">
                <p className="price">{dashboardData.rating}</p>
              </div>
            </div>
          </li>
        );
      });
    }
  }, [dashboardData]);

  var rating = 0;
  var totalPurchases = 0;
  if (dashboardData?.length > 0) {
    dashboardData.map((dashboardData) => {
      rating = rating + dashboardData.rating;
      if (dashboardData.earning.sales_commission_paid) totalPurchases += 1;
      const a = moment(new Date().toUTCString()).diff(
        moment(new Date(dashboardData.created_at).toUTCString()),
        "days"
      );
      if (a < 30) {
        if (
          dashboardData.earning.earning_paid &&
          dashboardData.earning.sales_commission_paid
        ) {
          postData =
            postData +
            (parseFloat(dashboardData.earning.earning) +
              parseFloat(dashboardData.earning.sales_commission));
        } else if (
          dashboardData.earning.earning_paid &&
          !dashboardData.earning.sales_commission_paid
        ) {
          preData = preData + parseFloat(dashboardData.earning.earning);
        }
      }
    });
  }
  // TODO : uncomment this code for Charts
  // const dataChart = {
  //   labels: ["Demo completion reward", "Post demo purchases"],
  //   datasets: [
  //     {
  //       label: "Daily view",
  //       data: [preData, postData],
  //       backgroundColor: ["#359CD7", "#24BCB7"],
  //       hoverOffset: 4,
  //     },
  //   ],
  // };

  // const changeProduct = (param) => {
  //   setLoading(true);
  //   let res = getUserReviews(`${param}`);
  //   res.then(function (response) {
  //     setLoading(false);
  //   });
  // };
  const getShowroomsDashboard = useCallback(() => {
    if (showrooms.length < 1) {
      return (
        <li className="list-group-item">No product Found on given category</li>
      );
    } else {
      return showrooms.map((showrooms, index) => {
        if (
          showrooms.accept_status === "ACTIVE" ||
          showrooms.accept_status === "PENDING"
        ) {
          return (
            <li key={index} className="list-group-item">
              <div className="row d-flex">
                <div className="col col-md-2">
                  <img
                    className="small-image-list"
                    src={showrooms.product.image}
                    alt="product"
                  />
                </div>
                <div className="col-sm-12 col-md-7 product--name--wrapper">
                  <p className="product-title">{showrooms?.product?.name}</p>{" "}
                  <div
                    className={`${
                      showrooms.accept_status === "ACTIVE"
                        ? "active-status"
                        : "pending-status"
                    }`}
                  >
                    <p>{showrooms.accept_status}</p>
                  </div>
                  <p className="company">{showrooms.product.store.name}</p>
                </div>
                <div className="col col-md-3 product--price--wrapper">
                  <p className="price">${showrooms.product.price}</p>
                </div>
              </div>
            </li>
          );
        }

        return <></>;
      });
    }
  }, [showrooms]);

  const getDoughnutdata = useCallback((type) => {
    var pre = 0,
      post = 0;
    const getData = (val) => {
      dashboardData.map((dashboardData) => {
        const a = moment(new Date().toUTCString()).diff(
          moment(new Date(dashboardData.created_at).toUTCString()),
          "days"
        );
        // if (a < val) {
        if (
          dashboardData.earning.earning_paid &&
          dashboardData.earning.sales_commission_paid
        ) {
          post =
            post +
            (parseFloat(dashboardData.earning.earning) +
              parseFloat(dashboardData.earning.sales_commission));
        } else if (
          dashboardData.earning.earning_paid &&
          !dashboardData.earning.sales_commission_paid
        ) {
          pre = pre + parseFloat(dashboardData.earning.earning);
        }
        // }
      });

      setPreData(pre);
      setPostData(post);
    };
    if (type === "day") {
      getData(1);
    } else if (type === "week") {
      getData(7);
    } else if (type === "month") {
      getData(30);
    }
  });

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const first = getUserReviews("?order=true&limit=12", { signal });
    const second = userDemos({ signal });
    const third = hostDashboardData({ signal });
    const forth = getShopifyProduct({ signal });
    const promise = Promise.all([first, second, third, forth]);
    promise
      .then((data) => {
        setLoading(false);
        notificationPermission();
      })
      .catch((err) => {
        setLoading(true);
      });

    return () => {
      controller.abort();
      // console.log("aborted`");
    };
  }, []);

  useEffect(() => {
    getDoughnutdata(active);
  });

 /**
  * This function is used to get all the stores in order to connect with them for a host
  *
  * @return {void}
  */

  const getAllStores = async () => {
    try {
      const storesList = await getAllStoresForHost();
      setStoresLoading(false);
      setStoreList(storesList?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffectOnce(() => {
    if (user.role === 3) {
      getAllStores();
    }
  }, []);

  //calculating remaining days until next friday
  // var currentDate = new Date();
  // var currentDay = currentDate.getDay();
  // var daysRemaining = (5 - currentDay + 7) % 7;

  // const textCenterDoughnut = {
  //   id: "textCenter",
  //   beforeDatasetsDraw(chart) {
  //     const { ctx } = chart;
  //     const text = "";
  //     // const text = `$${orderCompleted}, Next Payout in ${daysRemaining} ${
  //     //   daysRemaining === 1 ? "Day" : "Days"
  //     // } $${pendingOrder} Pending Order Fulfilment`;
  //     const maxWidth = 150;
  //     const lineHeight = 20;

  //     ctx.save();
  //     ctx.font = "bolder 12px sans-serif";
  //     ctx.textAlign = "center";
  //     ctx.textBaseline = "middle";

  //     let y = chart.getDatasetMeta(0).data[0].y;
  //     const words = text.split(" ");
  //     let line = "";

  //     words.forEach((word) => {
  //       const testLine = line + word + " ";
  //       const { width } = ctx.measureText(testLine);

  //       if (width > maxWidth) {
  //         ctx.fillText(line, chart.getDatasetMeta(0).data[0].x, y);
  //         line = word + " ";
  //         y += lineHeight;
  //       } else {
  //         line = testLine;
  //       }
  //     });
  //     ctx.fillText(line, chart.getDatasetMeta(0).data[0].x, y);
  //     ctx.restore();
  //   },
  // };

  if (loading || emailVerificationLoading) {
    return <PurebrandLoader />;
  } else {
    return (
      <>
        {showrooms.length < 1 ? (
          <div className="  text-center  ">
            <h4 className="p-5">
              To get started, review your experience and submit an image of your
              product.
            </h4>
            <div className=" text-center">
              <button
                className="btn btn-primary "
                onClick={() => navigate("/user/addproduct")}
              >
                + Add Product
              </button>
            </div>
          </div>
        ) : (
          <div className="row d-flex">
            <div className="col-sm-12 col-md-12 col-lg-9">
              <SuggestedProducts
                productList={productList}
                showrooms={showrooms}
              />
              <div className="row homepage-wrappers d-flex">
                {/* TODO : if we want circle graph in future , just uncomment below code */}
                {/* <Dashelement className="chart-host-wrapper col-sm-12 col-md-4">
                  <div className="features d-flex chart-wrap">
                    <nav className="nav d-flex">
                      <p>Payments</p>
                      <ul className="chart-nav">
                        <li
                          className={`nav-link ${
                            active === "day" ? "active-selection" : ""
                          }`}
                          onClick={() => {
                            setActive("day");
                            getDoughnutdata("day");
                          }}
                        >
                          Day
                        </li>
                        <li
                          className={`nav-link ${
                            active === "week" ? "active-selection" : ""
                          }`}
                          onClick={() => {
                            setActive("week");
                            getDoughnutdata("week");
                          }}
                        >
                          Week
                        </li>
                        <li
                          className={`nav-link ${
                            active === "month" ? "active-selection" : ""
                          }`}
                          onClick={() => {
                            setActive("month");
                            getDoughnutdata("month");
                          }}
                        >
                          Mon
                        </li>
                      </ul>
                    </nav>
                    {preData === 0 && postData === 0 ? (
                      <div>No data for the given time frame</div>
                    ) : (
                      <>
                        {" "}
                        <PurebrandChart
                          class="host-dashboard-chart"
                          options={options}
                          data={dataChart}
                          plugins={[textCenterDoughnut]}
                        />
                        <p className="text-center p-1">
                          <span className="h3 bold"> ${orderCompleted}</span>
                          <span className="h4 bold">
                            <br /> Next Payout
                            <br /> {daysRemaining} &nbsp;{" "}
                            {daysRemaining === 1 ? "Day" : "Days"}
                          </span>
                          <span className="h6">
                            <br />${pendingOrder} Pending <br /> Order
                            Fulfilment
                          </span>
                        </p>
                      </>
                    )}
                  </div>
                </Dashelement> */}
                <Dashelement className="linechart-host-wrapper linechart-host-wrapper-full col-sm-12 col-md-8">
                  <div className="homepage-white-wrap">
                    <div className="title-rating row">
                      <div className="title-rating-text col-4">
                        <p>Your demo ratings</p>
                      </div>

                      <div className="title-rating-element col-8">
                        <div className="row rating-title-wrap d-flex">
                          <div className="col col-1">
                            <img
                              className="pb-icon"
                              src={Icon}
                              alt="purebrand"
                            />
                          </div>

                          <div className="col col-4">
                            <ProgressBar
                              className="progress-bar-custom"
                              striped
                              variant="info"
                              now={rating / dashboardData.length}
                            />
                          </div>
                          <div className=" col col-6 text-end">
                            <p className="text-end ">
                              Avg. rating:{" "}
                              {parseInt(rating / dashboardData.length)}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-item-rating-dashboard">
                      <ul className="list-group list-group-flush">
                        {getRatingData()}
                      </ul>
                    </div>
                  </div>
                </Dashelement>
              </div>
              <div className="row homepage-bottom-wrapper d-flex">
                <Dashelement className="homepage-product-list-wrapper col-sm-12 col-md-6">
                  <div className="homepage-product-list homepage-white-wrap">
                    <div className="product-list-dashboard">
                      <p>My Products</p>

                      {/*  <nav className="nav">
                        <p>My Products</p>
                        <ul className="chart-nav">
                          <li
                            className={`nav-link ${
                              currentProductList === "all"
                                ? "active-selection"
                                : ""
                            }`}
                            onClick={() => {
                              setCurrentProductList("all");
                              changeProduct("?order=true&limit=12");
                            }}
                          >
                            All
                          </li>
                          <li
                            className={`nav-link ${
                              currentProductList === "technology"
                                ? "active-selection"
                                : ""
                            }`}
                            onClick={() => {
                              setCurrentProductList("technology");
                              changeProduct(
                                "?order=true&limit=12&tag=technology"
                              );
                            }}
                          >
                            Technology
                          </li>
                          <li
                            className={`nav-link ${
                              currentProductList === "furniture"
                                ? "active-selection"
                                : ""
                            }`}
                            onClick={() => {
                              setCurrentProductList("furniture");
                              changeProduct(
                                "?order=true&limit=12&tag=furniture"
                              );
                            }}
                          >
                            Furniture
                          </li>
                          <li
                            className={`nav-link ${
                              currentProductList === "misc"
                                ? "active-selection"
                                : ""
                            }`}
                            onClick={() => {
                              setCurrentProductList("misc");
                              changeProduct("?order=true&limit=12&tag=misc");
                            }}
                          >
                            Misc
                          </li> 
                        </ul>
                      </nav>*/}
                      <ul className="list-group list-group-flush">
                        {getShowroomsDashboard(currentProductList)}
                      </ul>
                    </div>
                    <div className="button-wrapper-homepage-products text-center">
                      <button
                        className="btn btn-homepage-add"
                        onClick={() => navigate("/user/addproduct")}
                      >
                        + Add Product
                      </button>
                    </div>
                  </div>
                </Dashelement>
                <div className="bottom-second-col col-sm-12 col-md-6">
                  <div className="row">
                    <Dashelement className="purchase-rate-wrapper">
                      <div className="homepage-white-wrap purchase-rate-home">
                        <div className="homepage-request-title">
                          <h4 className="title-home-request">Purchase Rate:</h4>
                          <h2 className="text-center">
                            {totalPurchases / dashboardData.length
                              ? (
                                  (totalPurchases / dashboardData.length) *
                                  100
                                ).toFixed(2)
                              : 0}
                            %
                          </h2>
                        </div>
                      </div>
                    </Dashelement>
                    <Dashelement className="request-wrapper">
                      <div className="homepage-white-wrap request-home">
                        <div className="homepage-request-title">
                          <h4 className="title-home-request">
                            Pending Requests: {demoRequests.length}
                          </h4>
                        </div>
                        <div className="demo-requests-home">
                          {getDemoRequest()}
                        </div>
                      </div>
                    </Dashelement>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-3 user-homepage-right-wrapper">
              <div className="user-detail-wrapper">
                <div className="user-smalldetail-wrapper text-center">
                  <img
                    className="dashboard-side-photo"
                    src={
                      userProfile?.profile_picture?.file
                        ? userProfile.profile_picture.file
                        : BlankImg
                    }
                    alt="profile"
                  />
                  <h4 className="title">
                    {user?.firstName} {(user?.lastName).charAt(0)}.
                  </h4>
                  <p>{userProfile?.about_me}</p>
                </div>
                <div className="calendar-small-wrapper">
                  <DatePicker
                    selected={new Date()}
                    className="home-datepicker"
                    onChange={() => navigate("/user/meetings")}
                    inline
                  />
                </div>
              </div>
              <hr />
              <div className="application-change-log-wrapper mt-3">
                <h5
                  style={{ textDecoration: "underline" }}
                  className="text-center mt-3"
                >
                  New Change Logs
                </h5>
                <ol>
                  <li>Camera switch icon on call.</li>
                  <li>Email/SMS on rescheduling or canceling.</li>
                  <li>Fixes on product editing.</li>
                  <li>
                    See the missed hosting opportunity on the "Scheduled" tab in{" "}
                    <span
                      onClick={() => navigate("/user/meetings")}
                      className="redirect_page"
                    >
                      My Demos + Requests
                    </span>
                    .
                  </li>
                  {/* <li>Sound notification when a user joins/leaves the demo.</li> */}
                  <li>Popup if the other party is yet to join the call.</li>
                </ol>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
};

export default HomePageHost;
