import { Col, Radio } from 'antd';
import React from 'react';
import './Commission.css';
import PropTypes from 'prop-types';
import { COMMISSION_FILTER } from 'utils/constants';

/**
 * This component renders the section of Commission Filter tab in the filters modal
 *
 * @param {props} props containing the state information of the filter Commission
 * @return {ReactNode} A React element that renders the section of Commission Filter tab in the filters modal
 */
const Commission = ({ setCommissionFilter, commissionFilter }) => {

  const onChange = (e) => {
      setCommissionFilter(e.target.value);
  };

  return (
    <div className="commission-filter">
      <Radio.Group
        style={{
          width: '100%',
        }}
        value={commissionFilter}
        onChange={onChange}
      >
        <Col>
        {COMMISSION_FILTER?.map((item) => {
          return (
            <div className="col-sm-12 px-3 py-2">
              <Radio key={item.label} value={item.value}>
                      {item.label}
                    </Radio>
            </div>
          );
        })}
        </Col>
      </Radio.Group>
    </div>
  );
};

Commission.propTypes = {
  setCommissionFilter: PropTypes.func,
  commissionFilter: PropTypes.string,
};

export default Commission;
