import React, { useEffect, useRef, useState } from 'react';
import './PaymentStoreFilterbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

const PaymentStoreFilterbar = ({ onFilterClick, displayFilters }) => {
  const filterBarRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const checkOverflow = () => {
    if (filterBarRef.current) {
      const hasOverflow = Array.from(filterBarRef.current.children).some(
        (item) => item.scrollHeight > item.clientHeight
      );
      setIsExpanded(hasOverflow);
    }
  };

  useEffect(() => {
    checkOverflow();
  }, [displayFilters]);

  return (
    <div ref={filterBarRef} className={`filter-bar ${isExpanded ? 'expanded' : ''}`}>
      <div className="filter-item" onClick={() => onFilterClick('1')}>
        <div className="filter-label">Earn Date <FontAwesomeIcon icon={faCaretDown} /></div>
        <div className="filter-value">{displayFilters.date || 'Select Date'}</div>
      </div>

      <div className="filter-item" onClick={() => onFilterClick('2')}>
        <div className="filter-label">Host <FontAwesomeIcon icon={faCaretDown} /></div>
        <div className="filter-value">
          {displayFilters.host?.length >= 1 ? displayFilters.host.join(', ') : 'Select Host'}
        </div>
      </div>

      <div className="filter-item" onClick={() => onFilterClick('3')}>
        <div className="filter-label">Product <FontAwesomeIcon icon={faCaretDown} /></div>
        <div className="filter-value">
          {displayFilters.product?.length >= 1 ? displayFilters.product.join(', ') : 'Select Product'}
        </div>
      </div>

      <div className="filter-item" onClick={() => onFilterClick('4')}>
        <div className="filter-label">Status <FontAwesomeIcon icon={faCaretDown} /></div>
        <div className="filter-value">{displayFilters.status || 'Select Status'}</div>
      </div>

      <div className="filter-item" onClick={() => onFilterClick('5')}>
        <div className="filter-label">Commission <FontAwesomeIcon icon={faCaretDown} /></div>
        <div className="filter-value">{displayFilters.commission || 'Select Commission'}</div>
      </div>
    </div>
  );
};

export default PaymentStoreFilterbar;
