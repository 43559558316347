import { Col, Radio } from 'antd';
import React from 'react';
import './Status.css';
import PropTypes from 'prop-types';

/**
 * This component renders the section of Status Filter tab in the filters modal
 *
 * @param {props} props containing the state information of the filter Status
 * @return {ReactNode} A React element that renders the section of Status Filter tab in the filters modal
 */
const Status = ({ setStatusFilter, statusFilter, originalStatusFilter, setDisplayFilter }) => {
  const allStatus = ["Unpaid", "Paid", "Failed"];

  const onChange = (e) => {
      setStatusFilter(e.target.value);
  };
  return (
    <div className="status-filter">
      <Radio.Group
        style={{
          width: '100%',
        }}
        value={statusFilter}
        onChange={onChange}
      >
        <Col>
        {allStatus?.map((status, index) => {
          return (
            <div className="col-sm-12 px-3 py-2">
              <Radio value={status} key={index}>
                {' '}
                {status}
              </Radio>
            </div>
          );
        })}
        </Col>
      </Radio.Group>
    </div>
  );
};

Status.propTypes = {
  setStatusFilter: PropTypes.func,
  statusFilter: PropTypes.array || PropTypes.string,
};

export default Status;
