import PurebrandLoader from "components/Loader/PurebrandLoader";
import PurebrandToast from "components/Toasts/Toast";
import AuthContext from "context/AuthContext";
import DataContext from "context/DataContext";
import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { capitalizeFirst } from "utils/stringFormatter";

const AssignNewRole = () => {
  const { assignNewRole } = useContext(DataContext);
  let { userProfile } = useContext(AuthContext);

  const [validated, setValidated] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [roleId, setRoleId] = useState("");

  const toastPopup = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };

  const toastError = () => {
    setShowErr(true);
    setTimeout(() => {
      setShowErr(false);
    }, 5000);
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    setValidated(true);

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    let data = {
      email: email,
      first_name: capitalizeFirst(fname),
      last_name: capitalizeFirst(lname),
      role: roleId,
      store_identifier: userProfile?.store?.store_id,
    };

    try {
      let res = await assignNewRole(data);
      if (res.status === 201) {
        setEmail("");
        setFName("");
        setLName("");
        setRoleId("");
        toastPopup();
        setLoading(false);
        setValidated(false);
      }
    } catch (err) {
      setEmail("");
      setFName("");
      setLName("");
      toastError();
      setRoleId("");
      setLoading(false);
      setValidated(false);
    }
  };

  if (loading) {
    return <PurebrandLoader />;
  }

  return (
    <>
      <PurebrandToast
        show={showToast}
        onClose={handleCloseToast}
        title="Success"
        message="Successfully assigned."
      />
      <div className="card cardc p-5">
        <div className="h5">Assign New Role</div>
        <hr />
        {showErr && <p className="text-danger">User Email already exist</p>}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group>
            <div className="row mb-3">
              <div className="col-6 col-sm-12 col-md-6">
                <Form.Label>First name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="First name"
                  onChange={(e) => {
                    setFName(e.target.value);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter First Name.
                </Form.Control.Feedback>
              </div>
              <div className="col-6 col-sm-12 col-md-6">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Last name"
                  onChange={(e) => {
                    setLName(e.target.value);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Last Name.
                </Form.Control.Feedback>
              </div>
            </div>
          </Form.Group>
          <Form.Group>
            <div className="row mb-3">
              <div className="col-6 col-sm-12 col-md-6">
                <Form.Label>Enter Email</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="Enter Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid email.
                </Form.Control.Feedback>
              </div>
              <div className="col-6 col-sm-12 col-md-6">
                <Form.Label>Assign Role</Form.Label>
                <Form.Control
                  as="select"
                  className="form-select"
                  defaultValue=""
                  required
                  onChange={(e) => {
                    setRoleId(e.target.value);
                  }}
                >
                  <option value="" disabled>
                    Select Role
                  </option>
                  <option value="4">Owner</option>
                  <option value="6">Edit Admin</option>
                  <option value="5">View Only</option>
                  <option value="3">Sales Person</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please assign role.
                </Form.Control.Feedback>
              </div>
            </div>
          </Form.Group>
        </Form>

        <div className="text-end m-2">
          <button
            className="btn btn-primary"
            type="submit"
            onClick={handleSubmit}
            disabled={roleId === ""}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default AssignNewRole;
