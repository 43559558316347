import { Icon, ProfilePicture } from "assets/images";
import AuthContext from "context/AuthContext";
import DemoContext from "context/DemoContext";
import ProfileContext from "context/ProfileContext";
import { Field, Form, Formik } from "formik";
import CustomLists from "Pages/dashboard/store/Hosts/CustomLists";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import "./modal.css";

const ProductModal = (props) => {
  let { user } = useContext(AuthContext);
  let { updateHostRequests, updateHostRequestsAdmin } =
    useContext(ProfileContext);
  let { respondReviewRequest, respondReviewRequestAdmin } =
    useContext(DemoContext);
  const [reject, setReject] = useState(false);
  const finalRequest = (value) => {
    if (user?.role === 1) {
      const response = respondReviewRequestAdmin(value, props.hostInfo.id);
      response
        .then(function (response) {
          if (response.status === 200) {
            props.clickStatus();
            updateHostRequestsAdmin("?order=true");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      const response = respondReviewRequest(value, props.hostInfo.id);
      response
        .then(function (response) {
          if (response.status === 200) {
            props.clickStatus();
            updateHostRequests("?order=true&active=false");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const handleReject = (e) => {
    if (user?.role === 1) {
      const data = {
        accept_status: "ADMIN_REJECTED",
        decline_reason: e.reason,
      };
      finalRequest(data);
      setReject(false);
    } else {
      const data = {
        accept_status: "DECLINED",
        decline_reason: e.reason,
      };
      finalRequest(data);
      setReject(false);
    }
  };
  const handleAccept = () => {
    const data = {
      accept_status: "ACTIVE",
      decline_reason: "none",
    };
    finalRequest(data);
  };
  const getButton = () => {
    if (props.buttonOption === "NotSure") {
      return (
        <>
          <Button
            variant="outline-danger submit-purebrand-bt"
            onClick={() => setReject(true)}
          >
            Submit
            <p className="small-button-text submit-purebrand">
              Submit for Purebrand Review.
            </p>
          </Button>

          <Button variant="outline-primary" onClick={handleAccept}>
            Accept
          </Button>
        </>
      );
    } else if (props.buttonOption === "Accept") {
      return (
        <>
          <Button
            variant="outline-danger submit-purebrand-btn"
            onClick={() => setReject(true)}
          >
            Submit
            <p className="small-button-text submit-purebrand">
              Submit for Purebrand Review.
            </p>
          </Button>
        </>
      );
    } else if (props.buttonOption === "Decline") {
      return (
        <>
          <Button variant="outline-primary" onClick={handleAccept}>
            Accept
          </Button>
          {user?.role === 1 ? (
            <Button variant="outline-danger" onClick={() => setReject(true)}>
              Reject
            </Button>
          ) : (
            <></>
          )}
        </>
      );
    }
  };
  return (
    <Modal
      className={props?.customClass ? props.customClass : ""}
      show={props.showStatus}
      onHide={props.clickStatus}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!reject ? (
          <ProductReviewInformation hostInfo={props.hostInfo} />
        ) : (
          <RejectReason handleReject={handleReject} />
        )}
      </Modal.Body>
      {props.buttonView ? (
        <Modal.Footer>
          {!reject ? (
            getButton()
          ) : (
            <Button variant="outline-primary" onClick={() => setReject(false)}>
              Go Back
            </Button>
          )}
        </Modal.Footer>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default ProductModal;

const ProductReviewInformation = (props) => {
  const [detail, setDetail] = useState(false);
  let host = props.hostInfo;
  let dateArray = host.created_at.split("T");
  if (!detail) {
    return (
      <div className="detail-wrapper">
        <div className="row information-row">
          <div className="col">
            <div className="row d-flex personal-info-row">
              <div className="col col-md-4 picture-col">
                <img
                  className="profile-picture-modal"
                  src={
                    host?.user?.profile_picture?.file
                      ? host.user.profile_picture.file
                      : ProfilePicture
                  }
                  alt="user"
                />
              </div>
              <div className="col col-md-8 name-column">
                <p className="product-rev-name-detail">
                  <strong>
                    {host.user.first_name} {host.user.last_name.charAt(0)}.
                  </strong>
                  <br />
                  Submitted: {dateArray[0]}
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row request-detail-wrapper">
          <div className="col col-md-6">
            <p>
              {host?.product?.name}
              <br />
              {host?.product?.store?.name}
              <br />
              {host.description}
            </p>
            <div className="row d-flex progress-bar-custom-wrap">
              <div className="col-sm-2 col-md-1 logo-wrapperr">
                <img className="pb-icon" src={Icon} alt="purebrand" />
              </div>

              <div className="col-sm-10 col-md-11 progress-bar-wrap">
                <ProgressBar
                  className="progress-bar-custom"
                  striped
                  variant="info"
                  now={host.rating}
                />
              </div>
              {host?.questionnaire?.default_set_at === null ? (
                <div className="rating-view-details-wrap col-sm-12 col-md-12">
                  <p>{parseInt(host.rating)}/100 </p>
                  <p
                    className="detail-information-rating"
                    onClick={() => setDetail(true)}
                  >
                    View Detail
                  </p>
                </div>
              ) : (
                <div className="rating-view-details-wrap col-sm-12 col-md-12">
                  <p>Soft Host</p>
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-12 col-md-6 product-image-review">
            <img
              className="product-image-shopify"
              src={host.product.image}
              alt="product"
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="review-wrapper">
        <div className="row another-information-row">
          <div className="col col-md-6">
            <div className="row d-flex personal-info-row">
              <div className="col col-md-4 picture-col">
                <img
                  className="profile-picture-modal"
                  src={
                    host?.user?.profile_picture?.file
                      ? host.user.profile_picture.file
                      : ProfilePicture
                  }
                  alt="user"
                />
              </div>
              <div className="col col-md-8 name-column">
                <p className="product-rev-name-detail">
                  <strong>
                    {host.user.first_name} {host.user.last_name.charAt(0)}.
                  </strong>
                  <br />
                  Submitted: {dateArray[0]}
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div className="col col-md-6">
            <div className="row d-flex">
              <div className="col col-md-1">
                <img className="pb-icon" src={Icon} alt="purebrand" />
              </div>

              <div className="col col-md-11">
                <ProgressBar
                  className="progress-bar-custom"
                  striped
                  variant="info"
                  now={host.rating}
                />
                <p className="text-start ">{parseInt(host.rating)}/100 </p>
              </div>
            </div>
          </div>
        </div>
        <div className="list-questions mt-3">
          <CustomLists hostInfo={host} />
        </div>
        <div
          onClick={() => setDetail(false)}
          className="detail-information-rating mt-3 mb-3"
        >
          Go Back
        </div>
      </div>
    );
  }
};

const RejectReason = (props) => {
  let { handleReject } = props;
  return (
    <div>
      <Formik initialValues={{ reason: "" }} onSubmit={handleReject}>
        {({ errors }) => (
          <Form>
            <label>Enter reason</label>
            <Field
              type="text"
              name="reason"
              placeholder="Reason for rejecting"
              className={`form-control`}
            />
            <button type="submit" className="btn btn-outline-danger mt-1">
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
